// LICENSE_CODE TLM
import Icon, {UploadOutlined, DownloadOutlined, ScissorOutlined, PauseOutlined,
  LeftOutlined, LockOutlined, LoadingOutlined, SearchOutlined, UndoOutlined,
  ArrowsAltOutlined, ShrinkOutlined, RedoOutlined, MoreOutlined,
  SoundOutlined} from '@ant-design/icons';
import {Button, message, Upload, Row, Space, Rate, Modal, Dropdown, Select,
  Typography, Col, Divider, Tooltip, Slider, Spin, Table, theme, Input,
  ConfigProvider, Popover} from 'antd';
import {gray, cyan, purple} from '@ant-design/colors';
import React, {useState, useRef, useEffect, useCallback, useMemo} from 'react';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import _ from 'lodash';
import assert from 'assert';
import {tinykeys} from 'tinykeys';
import xurl from '../../../util/xurl.js';
import xutil from '../../../util/util.js';
import ereq from '../../../util/ereq.js';
import eserf from '../../../util/eserf.js';
import xdate from '../../../util/date.js';
import auth from './auth.js';
import Contact from './contact.js';
import config_ext from './config_ext.js';
import {Clickable, Loading, download, Desktop_required_modal, use_je,
  use_qs, use_qs_clear, use_effect_eserf} from './comp.js';
import back_app from './back_app.js';
import lbin_demo from './lbin_demo.js';
import metric from './metric.js';
import player from './player.js';
import tc from '../../../util/tc.js';
import str from '../../../util/str.js';
import deep_diff from 'deep-diff';
import {editor, Marker_seg, Mark_in_seg, Mark_out_seg, Mark_in_indicator,
  Mark_out_indicator} from './editor.js';
import {ReactComponent as Purple_icon} from './assets/purple_icon.svg';
import {ReactComponent as Motion_icon} from './assets/motion_icon.svg';
import {ReactComponent as Gray_icon} from './assets/gray_icon.svg';
import {ReactComponent as Play_icon} from './assets/play_icon.svg';
import {ReactComponent as Play_next_icon} from './assets/play_next_icon.svg';
import {ReactComponent as Play_prev_icon} from './assets/play_prev_icon.svg';
import {ReactComponent as Zoom_to_fit_icon}
  from './assets/zoom_to_fit_icon.svg';
import {ReactComponent as Audio_meter_icon}
  from './assets/audio_meter_icon.svg';
import {ReactComponent as Mark_in_icon} from './assets/mark_in_icon.svg';
import {ReactComponent as Mark_out_icon} from './assets/mark_out_icon.svg';
import {ReactComponent as Clear_both_marks_icon}
  from './assets/clear_both_marks_icon.svg';
import {ReactComponent as Extract_icon} from './assets/extract_icon.svg';
import {ReactComponent as Lift_icon} from './assets/lift_icon.svg';
import {ReactComponent as Marker_icon} from './assets/marker_icon.svg';
import {ReactComponent as Quad_split_icon} from './assets/quad_split_icon.svg';
import {ReactComponent as Marker_seg_icon} from './assets/marker_seg_icon.svg';
import {ReactComponent as First_frame_icon}
  from './assets/first_frame_icon.svg';
import {ReactComponent as Clip_first_frame_left_icon}
  from './assets/clip_first_frame_left_icon.svg';
import {ReactComponent as Clip_first_frame_right_icon}
  from './assets/clip_first_frame_right_icon.svg';
import {ReactComponent as Last_frame_icon} from './assets/last_frame_icon.svg';
import media_offline_poster from './assets/media_offline_poster.jpeg';

let {Title} = Typography;
let prefix = config_ext.back.app.url;

let is_allow_edit = false;
let is_allow_solo_mute = true;

// XXX vladimir: move to comp.js
let key_binding_map_get = action2func=>{
  return Object.entries(action2func)
    .map(([action, func])=>{
      let key_bind = action2key_bind[action];
      if (Array.isArray(key_bind))
      {
        return key_bind
          .map(key=>({
            [key]: e=>{
              if (['INPUT', 'TEXTAREA'].includes(e.target.tagName))
                return;
              e.preventDefault();
              if (typeof func == 'function')
                func(e);
            }
          }))
          .reduce((accum, curr)=>({...accum, ...curr}));
      }
      return {
        [action2key_bind[action]]: e=>{
          if (['INPUT', 'TEXTAREA'].includes(e.target.tagName))
            return;
          e.preventDefault();
          if (typeof func == 'function')
            func(e);
        },
      };
    })
    .reduce((accum, cur)=>({...accum, ...cur}));
};
let coords_get = elem=>{
  let box = elem.getBoundingClientRect();
  return {top: box.top + window.scrollY, right: box.right + window.scrollX,
    bottom: box.bottom + window.scrollY, left: box.left + window.scrollX};
};
let lbin_sync = (rec_monitor_in, rec_monitor_out)=>{
  let _rec_monitor_out = _.cloneDeep(rec_monitor_out);
  for (let i = 0; i < rec_monitor_out.tracks.length; i++)
  {
    _rec_monitor_out.tracks[i].is_edit = rec_monitor_in.tracks[i].is_edit;
    _rec_monitor_out.tracks[i].is_monitor_selected =
      rec_monitor_in.tracks[i].is_monitor_selected;
  }
  return _rec_monitor_out;
};
let src_seg_get = seg=>{
  if (seg.type == 'source_clip' && seg.src)
    return seg;
  if (seg.is_no_arr)
    return null;
  return seg.arr.find(src_seg_get);
};
let track_height2px = height=>{
  switch (height)
  {
  case 'tall':
    return 38;
  case 'tall_no_fill':
    return 32;
  case 'medium':
    return 32;
  case 'medium_no_fill':
    return 26;
  case 'small':
    return 26;
  case 'small_no_fill':
    return 20;
  default:
    return 32;
  }
};
let action2key_bind = {
  zoom_in: '$mod+]',
  zoom_out: '$mod+[',
  change_selector_prev: 'ArrowUp',
  change_selector_next: 'ArrowDown',
  move_one_frame_left: 'ArrowLeft',
  move_one_frame_right: 'ArrowRight',
  move_ten_frames_left: 'Shift+ArrowLeft',
  move_ten_frames_right: 'Shift+ArrowRight',
  play_stop: 'Space',
  play_backwards: 'KeyJ',
  stop: 'KeyK',
  play: 'KeyL',
  go_to_prev_cut: 'KeyA',
  go_to_next_cut: 'KeyS',
  go_to_prev_marker: 'Shift+KeyA',
  go_to_next_marker: 'Shift+KeyS',
  go_to_mark_in: 'KeyQ',
  go_to_mark_out: 'KeyW',
  mark_in: 'KeyE',
  mark_out: 'KeyR',
  mark_clip: 'KeyT',
  clear_both_marks: 'KeyG',
  cut: 'KeyH',
  lift: 'KeyZ',
  extract: 'KeyX',
  marker_add: '=',
  focused_monitor_toggle: 'Escape',
  play_in_to_out: 'Alt+Space',
  undo: '$mod+z',
  redo: '$mod+r',
  mark_all_tracks: '$mod+a',
  unmark_all_tracks: '$mod+Shift+a',
};
let Editor_panel = React.memo(({children, style={}, ...rest})=>{
  return (
    <div {...rest} style={{width: '100%', height: '100%', display: 'flex',
      ...style}}>
      {children}
    </div>
  );
});
let min_zoom = -2;
let max_zoom = 12;
// XXX vladimir: move to player.js
let Scrub_bar = React.memo(({len, fps, frame, on_frame_change, arr=[],
  width='100%'})=>{
  let [container_width, container_width_set] = useState(0);
  let [ptr_moving, ptr_moving_set] = useState(false);
  let [ticks_gap, ticks_gap_set] = useState();
  let container_ref = useRef(null);
  let mouse_move_handle = useCallback(e=>{
    let container = container_ref.current;
    let container_offset = coords_get(container).left;
    let frame_rel = (e.clientX - container_offset) / container.offsetWidth;
    let _frame = len * frame_rel;
    on_frame_change(_frame);
  }, [on_frame_change, len]);
  let mouse_down_handle = useCallback(e=>{
    mouse_move_handle(e);
    ptr_moving_set(true);
  }, [mouse_move_handle]);
  let mouse_up_handle = useCallback(()=>ptr_moving_set(false), []);
  let resize_handle = useCallback(()=>{
    let _container_width = container_ref.current.offsetWidth;
    container_width_set(_container_width);
    let min_gap_px = 30;
    let t = len / fps;
    let min_gap_s = t * min_gap_px / _container_width;
    let periods_s = [0.04, 0.2, 1, 2, 5, 10, 15]; // 15 * 2 ^ n
    let min_period = periods_s.at(0);
    if (min_gap_s < periods_s.at(min_period))
    {
      let _ticks_gap = min_gap_s * _container_width / t;
      ticks_gap_set(_ticks_gap);
      return;
    }
    let sec_gap = periods_s.find((period, index)=>{
      return min_gap_s >= periods_s[index - 1] && min_gap_s <= period;
    });
    if (sec_gap)
    {
      let _ticks_gap = sec_gap * _container_width / t;
      ticks_gap_set(_ticks_gap);
      return;
    }
    let base_period = periods_s.at(-1);
    let power = Math.floor(Math.log2(2 * min_gap_s / base_period));
    sec_gap = base_period * 2 ** power;
    let _ticks_gap = sec_gap * _container_width / t;
    ticks_gap_set(_ticks_gap);
  }, [fps, len]);
  useEffect(()=>{
    let container = container_ref.current;
    let resize_observer = new ResizeObserver(resize_handle);
    resize_observer.observe(container);
    return ()=>resize_observer.unobserve(container);
  }, [resize_handle]);
  useEffect(()=>{
    if (!ptr_moving)
      return;
    document.addEventListener('mousemove', mouse_move_handle);
    document.addEventListener('mouseup', mouse_up_handle);
    return ()=>{
      document.removeEventListener('mousemove', mouse_move_handle);
      document.removeEventListener('mouseup', mouse_up_handle);
    };
  }, [mouse_move_handle, mouse_up_handle, ptr_moving]);
  let f2px_k = useMemo(()=>len / container_width,
    [container_width, len]);
  let ticks = useMemo(()=>{
    if (!ticks_gap)
      return [];
    let _ticks = [];
    for (let left = 0; left <= container_width; left += ticks_gap)
    {
      _ticks.push(<div key={left} style={{position: 'absolute',
        left: `${left}px`, transform: 'translateX(-50%)', bottom: 0,
        height: '8px', width: '1px', background: gray[9]}} />);
    }
    return _ticks;
  }, [container_width, ticks_gap]);
  let left = useMemo(()=>{
    return player.f2px(frame, f2px_k, 0);
  }, [f2px_k, frame]);
  return (
    <div ref={container_ref} style={{height: '16px', background: gray[7],
      position: 'relative', zIndex: 0, width, cursor: 'pointer'}}
    onMouseDown={mouse_down_handle} onTouchStart={mouse_down_handle}>
      <div style={{position: 'absolute', overflow: 'hidden', width: '100%',
        height: '100%'}}>
        {ticks}
        {arr.map((seg, idx)=><Seg key={idx} seg={seg} fps={fps}
          zoom={0} f2px_k={f2px_k} />)}
      </div>
      <Frame_ptr left={left} style={{background: purple.primary}} />
    </div>
  );
});
let Markers_table = React.memo(({lbin, query='', on_frame_change,
  cmts_col_width='40%', on_selected_objs_change, ...rest})=>{
  let {t} = useTranslation();
  let [selected_objs, selected_objs_set] = useState([]);
  let [last_selected_obj, last_selected_obj_set] = useState(null);
  let [is_ctx_open, is_ctx_open_set] = useState(false);
  let [ctx_row, ctx_row_set] = useState(null);
  let [table_sorter, table_sorter_set] = useState({});
  let [sorted_data_src, sorted_data_src_set] = useState([]);
  let cols = useMemo(()=>[
    {key: 'idx', dataIndex: 'idx', title: '#', hidden: true},
    {key: 'icon', dataIndex: 'icon', title: '',
      sorter: (a, b)=>str.cmp(b.marker.color, a.marker.color)},
    {key: 'name', dataIndex: 'name', title: t('Marker Name'),
      sorter: (a, b)=>str.cmp(b.name, a.name), hidden: true},
    {key: 'tc', dataIndex: 'tc', title: t('TC'),
      sorter: (a, b)=>b.marker.abs_start - a.marker.abs_start},
    {key: 'track', dataIndex: 'track', title: t('Track'),
      sorter: (a, b)=>str.cmp(b.track, a.track)},
    {key: 'cmt', dataIndex: 'cmt', title: t('Comment'),
      sorter: (a, b)=>str.cmp(b.cmt, a.cmt), width: cmts_col_width},
  ], [cmts_col_width, t]);
  let data_src = useMemo(()=>{
    if (!lbin?.rec_monitor_in)
      return [];
    let _query = query.toLowerCase().trim();
    let _data_src = [];
    let idx = 1;
    lbin.rec_monitor_in.tracks.forEach(track=>{
      let markers = editor.markers_get(track);
      markers = markers.filter(marker=>{
        return [...Object.values(marker), track.id].some(value=>{
          return String(value).toLowerCase().includes(_query);
        });
      });
      let track_data_src = markers.map(marker=>({cmt: marker.comment || '—',
        name: '—', track: track.id, idx: (idx++).toString().padStart(4, '0'),
        tc: tc.frame2tc(marker.abs_start, lbin.rec_monitor_in.editrate), marker,
        icon: <div style={{color: marker.color, display: 'flex',
          justifyContent: 'center'}}><Marker_seg_icon /></div>,
        // XXX vladimir: use real mob_id
        mob_id: (Math.random() * 9999999).toFixed().padStart(8, 0)}));
      _data_src = [..._data_src, ...track_data_src];
    });
    return _data_src;
  }, [lbin, query]);
  let row_handle = useCallback(record=>{
    let mob_id = record.mob_id;
    return {
      onMouseDown: e=>{
        let _selected_objs;
        if (e.shiftKey && !last_selected_obj)
          _selected_objs = [mob_id];
        else if (e.shiftKey && last_selected_obj)
        {
          let idx = sorted_data_src.findIndex(r=>r.mob_id == mob_id);
          let start_idx = Math.min(idx, sorted_data_src.findIndex(r=>{
            return r.mob_id == last_selected_obj;
          }));
          let end_idx = Math.max(idx, sorted_data_src.findIndex(r=>{
            return r.mob_id == last_selected_obj;
          }));
          let part_selected_files = sorted_data_src
            .slice(start_idx, end_idx + 1)
            .map(r=>r.mob_id);
          _selected_objs = [...selected_objs, ...part_selected_files];
        }
        else if ((e.ctrlKey || e.metaKey) && selected_objs.includes(mob_id))
          _selected_objs = selected_objs.filter(id=>id != mob_id);
        else if ((e.ctrlKey || e.metaKey) && !selected_objs.includes(mob_id))
          _selected_objs = [...selected_objs, mob_id];
        else
          _selected_objs = [mob_id];
        selected_objs_set(_selected_objs);
        if (on_selected_objs_change)
        {
          on_selected_objs_change(data_src.filter(_record=>{
            return _selected_objs.includes(_record.mob_id);
          }));
        }
        last_selected_obj_set(mob_id);
      },
      onDoubleClick: ()=>{
        on_frame_change(record.marker.abs_start);
      },
      onContextMenu: ()=>{
        is_ctx_open_set(true);
        ctx_row_set(record);
      },
    };
  }, [data_src, last_selected_obj, on_frame_change, on_selected_objs_change,
    selected_objs, sorted_data_src]);
  let row_class_name_handle = useCallback(record=>{
    if (selected_objs.includes(record.mob_id))
      return 'bin-table-row bin-table-row-selected';
    return 'bin-table-row';
  }, [selected_objs]);
  let dropdown_items = useMemo(()=>{
    return [
      {label: t('Jump to Marker'), key: 'jump_to_marker'},
      {label: t('Edit Marker'), key: 'edit_marker', disabled: true},
      {label: t('Change Color'), key: 'change_color ', disabled: true},
      {label: t('Change Track'), key: 'change_track ', disabled: true},
      {label: t('Import Markers'), key: 'import_markers ', disabled: true},
      {label: t('Export Markers'), key: 'export_markers ', disabled: true},
      {label: t('Choose Columns'), key: 'choose_markers ', disabled: true},
    ];
  }, [t]);
  let dropdown_click_handle = useCallback(({key})=>{
    if (key == 'jump_to_marker')
      on_frame_change(ctx_row.marker.abs_start);
  }, [ctx_row?.marker?.abs_start, on_frame_change]);
  let dropdown_open_change_handle = useCallback(is_open=>{
    if (!is_open)
      is_ctx_open_set(false);
  }, []);
  let table_change_handle = useCallback((pagination, filters, sorter,
    extra)=>{
    table_sorter_set(sorter);
    sorted_data_src_set(extra.currentDataSource);
  }, []);
  useEffect(()=>{
    if (table_sorter.field)
    {
      let sorted = [...data_src].sort((a, b)=>{
        if (table_sorter.order === 'ascend')
          return table_sorter.column.sorter(a, b);
        else if (table_sorter.order === 'descend')
          return table_sorter.column.sorter(b, a);
        return 0;
      });
      sorted_data_src_set(sorted);
      return;
    }
    sorted_data_src_set(data_src);
  }, [table_sorter, data_src]);
  return (
    <Dropdown menu={{items: dropdown_items,
      onClick: dropdown_click_handle}} trigger={['contextMenu']}
    open={is_ctx_open} onOpenChange={dropdown_open_change_handle}>
      <div>
        <Table columns={cols} dataSource={data_src} size="small"
          pagination={false} style={{width: '100%'}}
          onRow={row_handle} rowKey="mob_id"
          onChange={table_change_handle}
          rowClassName={row_class_name_handle} {...rest} />
      </div>
    </Dropdown>
  );
});
let Markers_modal = React.memo(({is_open, on_close, lbin, on_frame_change})=>{
  let {t} = useTranslation();
  let frame_change_hadnle = useCallback(frame=>{
    on_frame_change(frame);
    on_close();
  }, [on_close, on_frame_change]);
  return (
    <Modal title={t('Markers')} open={is_open} footer={null} width="80vw"
      onCancel={on_close} closeIcon={<ShrinkOutlined />}>
      <div>
        <Markers_table lbin={lbin} scroll={{y: 400}} cmts_col_width="60%"
          on_frame_change={frame_change_hadnle} />
      </div>
    </Modal>
  );
});
let Markers_panel = React.memo(({lbin, on_frame_change})=>{
  let {t} = useTranslation();
  let {token: {colorBgContainer: color_bg_container}} = theme.useToken();
  let table_container_ref = useRef(null);
  let [height, height_set] = useState(0);
  let [query, query_set] = useState('');
  let [is_markers_modal_open, is_markers_modal_open_set] = useState(false);
  let [selected_objs, selected_objs_set] = useState([]);
  let dropdown_items = useMemo(()=>{
    return [
      {label: t('Jump to Marker'), key: 'jump_to_marker'},
      {label: t('Edit Marker'), key: 'edit_marker', disabled: true},
      {label: t('Change Color'), key: 'change_color ', disabled: true},
      {label: t('Change Track'), key: 'change_track ', disabled: true},
      {label: t('Import Markers'), key: 'import_markers ', disabled: true},
      {label: t('Export Markers'), key: 'export_markers ', disabled: true},
      {label: t('Choose Columns'), key: 'choose_markers ', disabled: true},
    ];
  }, [t]);
  let resize_handle = useCallback(()=>{
    let container = table_container_ref.current;
    let container_rect = container.getBoundingClientRect();
    let container_height = container_rect.height;
    let table_header = container.querySelector('.ant-table-header');
    let table_header_rect = table_header.getBoundingClientRect();
    let table_header_height = table_header_rect.height;
    let _height = Math.floor(container_height - table_header_height);
    height_set(_height);
  }, []);
  useEffect(()=>{
    let container = table_container_ref.current;
    let resize_observer = new ResizeObserver(resize_handle);
    resize_observer.observe(container);
    return ()=>resize_observer.unobserve(container);
  }, [resize_handle]);
  let query_change_handle = useCallback(e=>{
    query_set(e.target.value);
  }, []);
  let markers_modal_open_handle = useCallback(()=>{
    is_markers_modal_open_set(true);
  }, []);
  let markers_modal_close_handle = useCallback(()=>{
    is_markers_modal_open_set(false);
  }, []);
  let dropdown_click_handle = useCallback(({key})=>{
    if (selected_objs.length != 1)
      return;
    let record = selected_objs[0];
    if (key == 'jump_to_marker')
      on_frame_change(record.marker.abs_start);
  }, [on_frame_change, selected_objs]);
  return (
    <ConfigProvider theme={{components: {Table: {borderRadius: 0,
      headerBorderRadius: 0}}}}>
      <Markers_modal is_open={is_markers_modal_open} lbin={lbin}
        on_close={markers_modal_close_handle}
        on_frame_change={on_frame_change} />
      <div style={{display: 'flex', flexDirection: 'column', height: '100%'}}>
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
          <Tooltip title={t('Expand')} placement="bottomRight">
            <Button icon={<ArrowsAltOutlined />} type="text"
              onClick={markers_modal_open_handle} />
          </Tooltip>
          <div style={{display: 'flex', gap: '8px'}}>
            <Input allowClear value={query} onChange={query_change_handle}
              prefix={<SearchOutlined style={{color: '#2A2A2A'}} />}
              style={{maxWidth: '200px'}} />
            <Dropdown menu={{items: dropdown_items,
              onClick: dropdown_click_handle}} trigger={['click']}
            disabled={selected_objs.length != 1}>
              <Button icon={<MoreOutlined />} type="text"
                disabled={selected_objs.length != 1} />
            </Dropdown>
          </div>
        </div>
        <div style={{height: '100%', background: color_bg_container}}
          ref={table_container_ref}>
          <Markers_table lbin={lbin} query={query} scroll={{y: height}}
            on_frame_change={on_frame_change}
            on_selected_objs_change={selected_objs_set} />
        </div>
      </div>
    </ConfigProvider>
  );
});
let Composer_panel = React.memo(({lbin, etag, fps, cmd_cut, token, cmd_mark_in,
  rec_playback_rate, rec_frame, cmd_mark_out, rec_playing_toggle, cmd_lift,
  cmd_extract, user, zoom, on_zoom_change, video_render_segs, audio_render_segs,
  cmd_clear_both_marks, aaf_in, cmd_marker_add, go_to_prev_cut, go_to_next_cut,
  on_rec_frame_change, on_playback_rate_change, undo, redo, loading_cmd,
  cur_lbin_change_idx, lbin_changes, on_loading_state_change, ...rest})=>{
  let {t} = useTranslation();
  let navigate = useNavigate();
  let {qs_o} = use_qs();
  let splits_container_ref = useRef(null);
  let initial_src = useMemo(()=>qs_o.src, [qs_o.src]);
  let [src_tc, src_tc_set] = useState(lbin?.rec_monitor_in?.tracks[0]?.id);
  let [rec_tc, rec_tc_set] = useState('abs');
  let [track_src_segs, track_src_segs_set] = useState(null);
  let [is_split, is_split_set] = useState(false);
  let [split_size, split_size_set] = useState(1);
  let [split_bank_idx, split_bank_idx_set] = useState(0);
  let [downloads_num, downloads_num_set] = useState(0);
  let [volume, volume_set] = useState(1);
  let [splits_width, splits_width_set] = useState(0);
  let zoom_to_fit = useCallback(()=>{
    on_zoom_change(0);
  }, [on_zoom_change]);
  let frame_change_handle = useCallback(_frame=>{
    on_rec_frame_change(_frame);
    on_playback_rate_change(0);
  }, [on_rec_frame_change, on_playback_rate_change]);
  let src_tc_select_opts = useMemo(()=>{
    if (!lbin?.rec_monitor_in)
      return [];
    return lbin.rec_monitor_in.tracks
      .filter(track=>track.type != 'tc_track')
      .map(track=>({value: track.id, label: track.lbl}));
  }, [lbin]);
  let src_tc_select_change_handle = useCallback(value=>{
    src_tc_set(value);
  }, []);
  let rec_tc_select_opts = useMemo(()=>{
    return [
      {value: 'mas', label: t('Master')},
      {value: 'abs', label: t('Absolute')},
    ];
  }, [t]);
  let rec_tc_select_change_handle = useCallback(value=>{
    rec_tc_set(value);
  }, []);
  let cur_src_tc = useMemo(()=>{
    if (!lbin?.rec_monitor_in || !track_src_segs)
      return '00:00:00:00';
    let src_segs = track_src_segs[src_tc];
    if (!src_segs)
      return '00:00:00:00';
    let src_seg = src_segs.find(seg=>{
      return player.frame_start(seg) <= rec_frame
        && rec_frame <= player.frame_end(seg);
    });
    if (!src_seg)
      return '01:00:00:00';
    let tc_str = tc.frame2tc(src_seg.start_tc + rec_frame - src_seg.abs_start,
      lbin.rec_monitor_in.editrate);
    return tc_str;
  }, [lbin, rec_frame, src_tc, track_src_segs]);
  let cur_rec_tc = useMemo(()=>{
    if (lbin?.rec_monitor_in?.start_tc === undefined
      || !lbin?.rec_monitor_in?.editrate)
    {
      return '00:00:00:00';
    }
    if (rec_tc == 'mas')
    {
      return tc.frame2tc(lbin.rec_monitor_in.start_tc + rec_frame,
        lbin.rec_monitor_in.editrate);
    }
    if (rec_tc == 'abs')
      return tc.frame2tc(rec_frame, lbin.rec_monitor_in.editrate);
  }, [lbin?.rec_monitor_in?.start_tc, lbin?.rec_monitor_in?.editrate,
    rec_frame, rec_tc]);
  let end_rec_tc = useMemo(()=>{
    if (!lbin?.rec_monitor_in?.editrate
      || lbin?.rec_monitor_in?.len === undefined
      || lbin?.rec_monitor_in?.start_tc === undefined)
    {
      return '00:00:00:00';
    }
    if (rec_tc == 'mas')
    {
      return tc.frame2tc(lbin.rec_monitor_in.start_tc + lbin.rec_monitor_in.len,
        lbin.rec_monitor_in.editrate);
    }
    if (rec_tc == 'abs')
      return tc.frame2tc(lbin.rec_monitor_in.len, lbin.rec_monitor_in.editrate);
  }, [lbin?.rec_monitor_in?.editrate, lbin?.rec_monitor_in?.len,
    lbin?.rec_monitor_in?.start_tc, rec_tc]);
  let aspect_ratio = useMemo(()=>{
    if (!lbin?.rec_monitor_in?.resolution)
      return 16 / 9;
    return lbin.rec_monitor_in.resolution.w / lbin.rec_monitor_in.resolution.h;
  }, [lbin?.rec_monitor_in?.resolution]);
  use_effect_eserf(()=>eserf(function* track_src_segs_get(){
    if (!lbin?.rec_monitor_in?.tracks)
      return;
    let video_tracks = lbin.rec_monitor_in.tracks
      .filter(track=>track.id[0] == 'V')
      .sort((track1, track2)=>{
        let num1 = parseInt(track1.id.slice(1), 10);
        let num2 = parseInt(track2.id.slice(1), 10);
        return num2 - num1;
      });
    let audio_tracks = lbin.rec_monitor_in.tracks
      .filter(track=>track.id[0] == 'A')
      .sort((track1, track2)=>{
        let num1 = parseInt(track1.id.slice(1), 10);
        let num2 = parseInt(track2.id.slice(1), 10);
        return num2 - num1;
      });
    let _track_src_segs = {};
    for (let idx = 0; idx < video_tracks.length; idx++)
    {
      let track = video_tracks[idx];
      _track_src_segs[track.id] = yield player.src_segs_get(token,
        video_tracks.slice(idx));
    }
    for (let idx = 0; idx < audio_tracks.length; idx++)
    {
      let track = audio_tracks[idx];
      _track_src_segs[track.id] = yield player.src_segs_get(token,
        audio_tracks.slice(idx));
    }
    track_src_segs_set(_track_src_segs);
  }), [lbin?.rec_monitor_in?.tracks, token]);
  useEffect(()=>{
    if (!lbin?.rec_monitor_in?.tracks)
      return;
    src_tc_set(lbin?.rec_monitor_in?.tracks[0]?.id);
  }, [lbin?.rec_monitor_in?.tracks]);
  let aaf_export = useCallback(()=>{
    if (!token || !lbin || !aaf_in || !user?.email)
      return;
    let basename = lbin.rec_monitor_in.lbl;
    let filename = `${basename}__v${downloads_num + 1}__TOOLIUM_ORG.aaf`;
    let url = config_ext.back.app.url + xurl.url('/private/aaf/get.aaf',
      {file: aaf_in, email: user.email, token, ver: config_ext.ver, filename});
    download(url, filename);
    downloads_num_set(downloads_num + 1);
  }, [aaf_in, downloads_num, token, user.email, lbin]);
  useEffect(()=>{
    downloads_num_set(0);
  }, [etag]);
  let resize_handle = useCallback(()=>{
    let container = splits_container_ref.current;
    let container_rect = container.getBoundingClientRect();
    let container_height = container_rect.height;
    let _splits_width = container_height * aspect_ratio;
    splits_width_set(_splits_width);
  }, [aspect_ratio]);
  useEffect(()=>{
    let container = splits_container_ref.current;
    // if splits mode is disabled
    if (!container)
      return;
    let resize_observer = new ResizeObserver(resize_handle);
    resize_observer.observe(container);
    return ()=>resize_observer.unobserve(container);
  }, [resize_handle]);
  let marker_add = useCallback(()=>{
    if (!cmd_marker_add)
      return;
    cmd_marker_add(lbin.rec_monitor_in.mob_id, lbin.rec_monitor_in.tracks[0].id,
      rec_frame, '#cc33cc');
  }, [cmd_marker_add, lbin, rec_frame]);
  let markers = useMemo(()=>{
    if (!lbin?.rec_monitor_in?.tracks)
      return [];
    return editor.markers_get(lbin.rec_monitor_in.tracks);
  }, [lbin?.rec_monitor_in?.tracks]);
  let timeline_arr = useMemo(()=>{
    if (!lbin?.rec_monitor_in)
      return [];
    let arr = [...markers];
    if (lbin.rec_monitor_in.mark_in)
    {
      arr.push({is_no_arr: true, len: 1, start: lbin.rec_monitor_in.mark_in,
        type: 'mark_in', zidx: 200});
    }
    if (lbin.rec_monitor_in.mark_out)
    {
      arr.push({is_no_arr: true, len: 1, start: lbin.rec_monitor_in.mark_out,
        type: 'mark_out', zidx: 200});
    }
    return arr;
  }, [lbin?.rec_monitor_in, markers]);
  let cur_video_render_seg = useMemo(()=>{
    return video_render_segs.find(render_seg=>{
      return player.frame_start(render_seg) <= rec_frame
        && rec_frame <= player.frame_end(render_seg);
    });
  }, [rec_frame, video_render_segs]);
  let splits_num = useMemo(()=>{
    if (!cur_video_render_seg?.split_render_segs)
      return 1;
    return cur_video_render_seg.split_render_segs.length;
  }, [cur_video_render_seg?.split_render_segs]);
  let split_view_toggle = useCallback(()=>{
    if (!cur_video_render_seg?.split_render_segs)
      return;
    if (is_split)
      return is_split_set(false);
    if (splits_num == 1)
      split_size_set(1);
    else if (splits_num <= 4)
      split_size_set(4);
    else
      split_size_set(9);
    split_bank_idx_set(0);
    is_split_set(true);
  }, [cur_video_render_seg?.split_render_segs, is_split, splits_num]);
  let split_size_click_handler_get = useCallback(_split_size=>{
    if (!cur_video_render_seg?.split_render_segs)
      return;
    return ()=>split_size_set(_split_size);
  }, [cur_video_render_seg?.split_render_segs]);
  let swap_cam_bank_click_handle = useCallback(()=>{
    if (!cur_video_render_seg?.split_render_segs || splits_num <= split_size)
      return;
    let _split_bank_idx = (split_bank_idx + split_size) % splits_num;
    split_bank_idx_set(_split_bank_idx);
  }, [cur_video_render_seg?.split_render_segs, split_bank_idx, split_size,
    splits_num]);
  let splits = useMemo(()=>{
    if (!is_split || !cur_video_render_seg
      || !cur_video_render_seg.split_render_segs.length)
    {
      return [[{video_render_segs, audio_render_segs}]];
    }
    let _splits = [];
    for (let render_seg of cur_video_render_seg.split_render_segs)
      _splits.push({video_render_segs: [render_seg]});
    let grid_size = Math.floor(Math.sqrt(split_size));
    let rows = [];
    for (let row_idx = 0; row_idx < grid_size; row_idx++)
    {
      let start_idx = split_bank_idx + row_idx * grid_size;
      let end_idx = split_bank_idx + (row_idx + 1) * grid_size;
      let row = _splits.slice(start_idx, end_idx);
      let empty_splits = new Array(grid_size - row.length).fill(null);
      row = [...row, ...empty_splits];
      rows.push(row);
    }
    return rows;
  }, [audio_render_segs, cur_video_render_seg, is_split, split_bank_idx,
    split_size, video_render_segs]);
  let back_handle = useCallback(()=>{
    navigate(xurl.url('/workspace', {...qs_o, path: qs_o.src_path,
      is_dir: qs_o.src_is_dir}));
  }, [navigate, qs_o]);
  let volume_change_handle = useCallback(_volume=>{
    volume_set(_volume / 100);
  }, []);
  let is_undo_disabled = useMemo(()=>{
    return cur_lbin_change_idx == -1;
  }, [cur_lbin_change_idx]);
  let is_redo_disabled = useMemo(()=>{
    return cur_lbin_change_idx == lbin_changes.length - 1;
  }, [cur_lbin_change_idx, lbin_changes.length]);
  let dropdown_items = useMemo(()=>{
    return [
      {label: t('Undo'), key: 'undo', disabled: is_undo_disabled,
        icon: <UndoOutlined />},
      {label: t('Redo'), key: 'redo', disabled: is_redo_disabled,
        icon: <RedoOutlined />},
      {label: t('Export'), key: 'export', disabled: !aaf_in,
        icon: <DownloadOutlined />},
    ];
  }, [aaf_in, is_redo_disabled, is_undo_disabled, t]);
  let dropdown_click_handle = useCallback(({key})=>{
    if (key == 'undo')
      undo();
    if (key == 'redo')
      redo();
    if (key == 'export')
      aaf_export();
  }, [aaf_export, redo, undo]);
  let is_mark_in = useMemo(()=>{
    if (!lbin?.rec_monitor_in?.mark_in)
      return false;
    return lbin.rec_monitor_in.mark_in == rec_frame;
  }, [lbin?.rec_monitor_in?.mark_in, rec_frame]);
  let is_mark_out = useMemo(()=>{
    if (!lbin?.rec_monitor_in?.mark_out)
      return false;
    return lbin.rec_monitor_in.mark_out == rec_frame;
  }, [lbin?.rec_monitor_in?.mark_out, rec_frame]);
  let is_first_frame = useMemo(()=>{
    if (!lbin?.rec_monitor_in)
      return false;
    return rec_frame == 0;
  }, [rec_frame, lbin?.rec_monitor_in]);
  let is_penultimate_frame = useMemo(()=>{
    if (!lbin?.rec_monitor_in?.len)
      return false;
    return rec_frame == lbin.rec_monitor_in.len - 1;
  }, [rec_frame, lbin?.rec_monitor_in?.len]);
  let is_last_frame = useMemo(()=>{
    if (!lbin?.rec_monitor_in?.len)
      return false;
    return rec_frame == lbin.rec_monitor_in.len;
  }, [rec_frame, lbin?.rec_monitor_in?.len]);
  let cur_marker = useMemo(()=>{
    return markers.find(seg=>seg.start == rec_frame);
  }, [rec_frame, markers]);
  let video_tracks = useMemo(()=>{
    if (!lbin?.rec_monitor_in?.tracks)
      return [];
    return lbin.rec_monitor_in.tracks.filter(track=>track.id[0] == 'V');
  }, [lbin?.rec_monitor_in?.tracks]);
  let is_clip_first_frame_left = useMemo(()=>{
    if (!video_tracks.length)
      return false;
    let cuts = editor.cuts_get(video_tracks);
    return cuts.some(cut=>cut == rec_frame);
  }, [rec_frame, video_tracks]);
  let is_clip_first_frame_right = useMemo(()=>{
    if (!video_tracks.length)
      return false;
    let cuts = editor.cuts_get(video_tracks);
    let next_frame = rec_frame + 1;
    return cuts.some(cut=>cut == next_frame);
  }, [rec_frame, video_tracks]);
  let mark_in = useCallback(()=>{
    if (!lbin)
      return;
    cmd_mark_in(lbin.rec_monitor_in.mob_id, rec_frame);
  }, [cmd_mark_in, lbin, rec_frame]);
  let mark_out = useCallback(()=>{
    if (!lbin)
      return;
    cmd_mark_out(lbin.rec_monitor_in.mob_id, rec_frame);
  }, [cmd_mark_out, lbin, rec_frame]);
  let clear_both_marks = useCallback(()=>{
    if (!lbin)
      return;
    cmd_clear_both_marks(lbin.rec_monitor_in.mob_id);
  }, [cmd_clear_both_marks, lbin]);
  let cut = useCallback(()=>{
    if (!lbin)
      return;
    cmd_cut(lbin.rec_monitor_in.mob_id, rec_frame);
  }, [cmd_cut, lbin, rec_frame]);
  let lift = useCallback(()=>{
    if (!lbin?.rec_monitor_in?.mark_in || !lbin?.rec_monitor_in?.mark_out)
      return;
    cmd_lift(lbin.rec_monitor_in.mob_id, lbin.rec_monitor_in.mark_in,
      lbin.rec_monitor_in.mark_out);
  }, [cmd_lift, lbin]);
  let extract = useCallback(()=>{
    if (!lbin?.rec_monitor_in?.mark_in || !lbin?.rec_monitor_in?.mark_out)
      return;
    cmd_extract(lbin.rec_monitor_in.mob_id, lbin.rec_monitor_in.mark_in,
      lbin.rec_monitor_in.mark_out);
  }, [cmd_extract, lbin]);
  let resolution = useMemo(()=>{
    if (!lbin?.rec_monitor_in)
      return {w: 1920, h: 1080};
    return lbin.rec_monitor_in.resolution;
  }, [lbin]);
  return (
    <Editor_panel style={{display: 'flex', flexDirection: 'column'}} {...rest}>
      <div style={{display: 'flex', justifyContent: 'space-between',
        alignItems: 'center', margin: '2px', padding: '8px'}}>
        <div style={{display: 'flex', alignItems: 'center', gap: '4px'}}>
          {initial_src && <Tooltip title={t('Back to Workspace')}
            placement="bottomRight">
            <Button icon={<LeftOutlined />} type="text"
              onClick={back_handle} />
          </Tooltip>}
          {lbin?.rec_monitor_in?.lbl && <div style={{maxWidth: '50vw',
            overflow: 'hidden', textOverflow: 'ellipsis', color: 'white'}}>
            {lbin.rec_monitor_in.lbl}
          </div>}
        </div>
        <div style={{display: 'flex', justifyContent: 'flex-end',
          alignItems: 'center', gap: '4px'}}>
          {loading_cmd && <div style={{display: 'flex', alignItems: 'center',
            gap: '12px', color: 'white', paddingRight: '8px'}}>
            <Spin indicator={<LoadingOutlined spin />} />
            {loading_cmd}
          </div>}
          <Tooltip title={t('Undo')} placement="bottom">
            <Button type="text" onClick={undo} icon={<UndoOutlined />}
              disabled={is_undo_disabled} />
          </Tooltip>
          <Tooltip title={t('Redo')} placement="bottom">
            <Button type="text" onClick={redo} icon={<RedoOutlined />}
              disabled={is_redo_disabled} />
          </Tooltip>
          <Button type="text" onClick={aaf_export} disabled={!aaf_in}>
            {t('Export')}
          </Button>
          <Dropdown menu={{items: dropdown_items,
            onClick: dropdown_click_handle}} trigger={['click']}>
            <Button type="text" icon={<MoreOutlined />} />
          </Dropdown>
        </div>
      </div>
      <Row style={{height: '100%'}}>
        <Col span={8} style={{height: '100%'}}>
          <Markers_panel lbin={lbin} on_frame_change={frame_change_handle} />
        </Col>
        <Col span={16}>
          <div style={{width: '100%', maxWidth: '100%', display: 'flex',
            height: '100%', flexDirection: 'column', background: '#222222',
            alignItems: 'center'}}>
            {is_split && <div style={{display: 'flex', justifyContent: 'center',
              alignItems: 'center', padding: '8px', background: gray[9],
              width: '100%'}}>
              <Button type="text" onClick={split_size_click_handler_get(1)}>
                {t('Single Cam')}
              </Button>
              <Button type="text" onClick={split_size_click_handler_get(4)}>
                {t('4 Split')}
              </Button>
              <Button type="text" onClick={split_size_click_handler_get(9)}>
                {t('9 Split')}
              </Button>
              <Button type="text" onClick={swap_cam_bank_click_handle}>
                {t('Swap Cam Bank')}
              </Button>
            </div>}
            <div style={{height: '100%', display: 'flex',
              justifyContent: 'center'}}>
              {lbin && <div style={{display: 'flex', flexDirection: 'column',
                height: '100%', position: 'relative'}}
              ref={splits_container_ref} onMouseDown={rec_playing_toggle}>
                {splits.map((row, row_idx)=><div key={row_idx}
                  style={{display: 'flex', height: '100%', width: splits_width,
                    justifyContent: 'center',
                    borderTop: row_idx != 0 ? '1px solid black' : 'none'}}>
                  {row.map((col, col_idx)=>{
                    if (!col)
                    {
                      return <div key={col_idx} style={{height: '100%',
                        aspectRatio: aspect_ratio}} />;
                    }
                    return (
                      <div key={col_idx} style={{position: 'relative',
                        height: '100%', aspectRatio: aspect_ratio,
                        cursor: 'pointer',
                        borderLeft: col_idx != 0 ? '1px solid black' : 'none'}}>
                        <player.Player video_render_segs={col.video_render_segs}
                          resolution={resolution} frame={rec_frame}
                          playback_rate={rec_playback_rate}
                          audio_render_segs={col.audio_render_segs} fps={fps}
                          is_audio_video_sync volume={volume}
                          on_loading_state_change={on_loading_state_change} />
                      </div>
                    );
                  })}
                </div>)}
                {is_mark_in && <Mark_in_indicator style={{left: 0, top: '50%',
                  transform: 'translateY(-50%)'}} />}
                {is_mark_out && <Mark_out_indicator style={{right: 0,
                  top: '50%', transform: 'translateY(-50%)'}} />}
                {is_first_frame && <div style={{position: 'absolute',
                  left: '5px', bottom: '2px'}}>
                  <First_frame_icon />
                </div>}
                {!is_first_frame && !is_last_frame
                  && is_clip_first_frame_left
                  && <div style={{position: 'absolute', left: '5px',
                    bottom: '2px'}}>
                    <Clip_first_frame_left_icon />
                  </div>}
                {!is_penultimate_frame && is_clip_first_frame_right
                  && <div style={{position: 'absolute', right: '5px',
                    bottom: '2px'}}>
                    <Clip_first_frame_right_icon />
                  </div>}
                {is_penultimate_frame && <div style={{position: 'absolute',
                  right: '5px', bottom: '2px'}}>
                  <Last_frame_icon />
                </div>}
                {is_last_frame && <div style={{position: 'absolute',
                  right: '5px', bottom: '2px'}}>
                  <Last_frame_icon />
                </div>}
                {cur_marker && <div style={{position: 'absolute',
                  display: 'flex', flexDirection: 'column',
                  alignItems: 'center', bottom: '4px', left: '50%',
                  transform: 'translateX(-50%)',
                  maxWidth: 'calc(100% - 40px)'}}>
                  <Icon component={Marker_seg_icon}
                    style={{color: cur_marker.color, fontSize: '24px'}} />
                  {cur_marker.comment && <span style={{color: 'white',
                    fontSize: '11px', maxWidth: '100%',
                    textShadow: '-1px 0 black, 0 1px black, 1px 0 black,'
                      +' 0 -1px black', whiteSpace: 'nowrap',
                    overflow: 'hidden', textOverflow: 'ellipsis'}}>
                    {cur_marker.comment}
                  </span>}
                </div>}
              </div>}
            </div>
            <Scrub_bar len={lbin?.rec_monitor_in?.len} fps={fps}
              frame={rec_frame} arr={timeline_arr} width={splits_width}
              on_frame_change={frame_change_handle} />
          </div>
        </Col>
      </Row>
      <div style={{display: 'flex', color: 'white',
        justifyContent: 'space-between', padding: '8px'}}>
        <div style={{display: 'flex', alignItems: 'center', gap: '4px'}}>
          <Tooltip title={t('Split')}>
            <Button type="text" onClick={cut} disabled={!aaf_in}
              icon={<ScissorOutlined />} />
          </Tooltip>
          <Tooltip title={t('Mark IN')}>
            <Button type="text" onClick={mark_in} disabled={!aaf_in}
              icon={<Mark_in_icon />} />
          </Tooltip>
          <Tooltip title={t('Mark OUT')}>
            <Button type="text" onClick={mark_out} disabled={!aaf_in}
              icon={<Mark_out_icon />} />
          </Tooltip>
          <Tooltip title={t('Clear Both Marks')}>
            <Button type="text" onClick={clear_both_marks}
              disabled={!aaf_in} icon={<Clear_both_marks_icon />} />
          </Tooltip>
          <Tooltip title={t('Extract')}>
            <Button type="text" onClick={extract} disabled={!aaf_in}
              icon={<Extract_icon />} />
          </Tooltip>
          <Tooltip title={t('Lift')}>
            <Button type="text" onClick={lift} disabled={!aaf_in}
              icon={<Lift_icon />} />
          </Tooltip>
          <Tooltip title={t('Add Marker')}>
            <Button type="text" onClick={marker_add} disabled={!aaf_in}
              icon={<Marker_icon />} />
          </Tooltip>
          <Tooltip title={t('Split View')}>
            <Button type="text" onClick={split_view_toggle}
              icon={<Quad_split_icon />} />
          </Tooltip>
        </div>
        <div style={{display: 'flex', justifyContent: 'center',
          alignItems: 'center', gap: '16px'}}>
          <Select options={src_tc_select_opts} style={{width: '150px'}}
            value={src_tc} onChange={src_tc_select_change_handle} />
          <div style={{display: 'flex', alignItems: 'center', gap: '8px',
            width: '100px', justifyContent: 'center'}}>
            {cur_src_tc}
          </div>
          <div style={{display: 'flex', alignItems: 'center'}}>
            <Button type="text" onClick={go_to_prev_cut}
              icon={<Icon style={{fontSize: '12px'}}
                component={Play_prev_icon} />} />
            <Button type="text" onClick={rec_playing_toggle}>
              {rec_playback_rate
                ? <PauseOutlined style={{fontSize: '24px'}} />
                : <Icon style={{fontSize: '24px'}}
                  component={Play_icon} />}
            </Button>
            <Button type="text" onClick={go_to_next_cut}
              icon={<Icon style={{fontSize: '12px'}}
                component={Play_next_icon} />} />
          </div>
          <div style={{display: 'flex', alignItems: 'center', gap: '8px',
            width: '200px', justifyContent: 'center'}}>
            <span>{cur_rec_tc}</span>
            <span> | </span>
            <span>{end_rec_tc}</span>
          </div>
          <Select options={rec_tc_select_opts} style={{width: '150px'}}
            value={rec_tc} onChange={rec_tc_select_change_handle} />
        </div>
        <div style={{display: 'flex', justifyContent: 'flex-end',
          alignItems: 'center', gap: '4px'}}>
          <Tooltip title={t('Zoom to fit')}>
            <Button type="text" onClick={zoom_to_fit}
              icon={<Icon component={Zoom_to_fit_icon} />} />
          </Tooltip>
          <Slider min={min_zoom} max={max_zoom} style={{width: '100px'}}
            onChange={on_zoom_change} value={zoom} step={0.1} />
          <Divider type="vertical" />
          <Popover content={<Slider min={0} max={200} value={volume * 100}
            onChange={volume_change_handle} step={1} style={{width: '200px'}}
            tooltip={{formatter: n=>`${n}%`}} />}>
            <Button type="text" icon={<SoundOutlined />} />
          </Popover>
          <Tooltip title={t('Audio meter')}>
            <Button type="text" disabled
              icon={<Icon component={Audio_meter_icon} />} />
          </Tooltip>
        </div>
      </div>
    </Editor_panel>
  );
});
let Track_actions = React.memo(({rec_track, cmd_toggle_lock, cmd_solo, cmd_mute,
  lbin})=>{
  let [rec_editing_tracks, rec_editing_tracks_set] = use_je(
    'highlighter.rec_editing_tracks', []);
  let [tracks_mark_sliding, tracks_mark_sliding_set] = use_je(
    'editor.tracks_mark_sliding', null);
  let no_fill = useMemo(()=>{
    return rec_track.height ? rec_track.height.endsWith('no_fill') : false;
  }, [rec_track.height]);
  let height = useMemo(()=>{
    return track_height2px(rec_track.height);
  }, [rec_track.height]);
  let is_audio_track = useMemo(()=>rec_track.id[0] == 'A', [rec_track.id]);
  let solo_handle = useCallback(e=>{
    let is_solo = !rec_track.solo.v;
    let track_ids;
    if (e.altKey)
    {
      track_ids = lbin.rec_monitor_in.tracks
        .filter(track=>track.id[0] == 'A' && track.solo.v != is_solo)
        .map(track=>track.id);
    }
    else
      track_ids = [rec_track.id];
    cmd_solo(lbin.rec_monitor_in.mob_id, track_ids, is_solo);
  }, [cmd_solo, rec_track, lbin]);
  let mute_handle = useCallback(e=>{
    let is_mute = rec_track.mute.v == !!rec_track.mute.is_solo;
    let track_ids;
    if (e.altKey)
    {
      track_ids = lbin.rec_monitor_in.tracks
        .filter(track=>track.id[0] == 'A' && track.mute.v != is_mute)
        .map(track=>track.id);
    }
    else
      track_ids = [rec_track.id];
    cmd_mute(lbin.rec_monitor_in.mob_id, track_ids, is_mute);
  }, [cmd_mute, rec_track, lbin]);
  let rec_mouse_enter_handle = useCallback(()=>{
    if (!tracks_mark_sliding || !rec_track)
      return;
    if (tracks_mark_sliding.is_editing
      && !rec_editing_tracks.includes(rec_track.id))
    {
      return rec_editing_tracks_set([...rec_editing_tracks, rec_track.id]);
    }
    if (!tracks_mark_sliding.is_editing
      && rec_editing_tracks.includes(rec_track.id))
    {
      let _rec_editing_tracks = [...rec_editing_tracks];
      let idx = _rec_editing_tracks.indexOf(rec_track.id);
      _rec_editing_tracks.splice(idx, 1);
      rec_editing_tracks_set(_rec_editing_tracks);
    }
  }, [rec_editing_tracks, rec_editing_tracks_set, tracks_mark_sliding,
    rec_track]);
  let mouse_up_handle = useCallback(()=>{
    tracks_mark_sliding_set(null);
  }, [tracks_mark_sliding_set]);
  let rec_edit_toggle_handle = useCallback(e=>{
    if (e.nativeEvent.which != 1)
      return;
    let is_editing = rec_editing_tracks.includes(rec_track.id);
    if (e.shiftKey)
      tracks_mark_sliding_set({is_editing: !is_editing});
    if (!is_editing)
      return rec_editing_tracks_set([...rec_editing_tracks, rec_track.id]);
    let _rec_editing_tracks = [...rec_editing_tracks];
    let idx = _rec_editing_tracks.indexOf(rec_track.id);
    _rec_editing_tracks.splice(idx, 1);
    rec_editing_tracks_set(_rec_editing_tracks);
  }, [rec_editing_tracks, rec_track.id, tracks_mark_sliding_set,
    rec_editing_tracks_set]);
  let is_editing = useMemo(()=>{
    return rec_editing_tracks.includes(rec_track.id);
  }, [rec_editing_tracks, rec_track.id]);
  useEffect(()=>{
    if (!tracks_mark_sliding)
      return;
    window.addEventListener('mouseup', mouse_up_handle);
    return ()=>{
      window.removeEventListener('mouseup', mouse_up_handle);
    };
  }, [mouse_up_handle, tracks_mark_sliding]);
  return (
    <div style={{display: 'flex', height: `${height}px`, padding: '0 8px',
      margin: no_fill && '3px 0', position: 'relative', marginBottom: '4px',
      width: '100%'}}>
      <Button type={is_editing ? 'primary': 'text'} style={{width: '36px'}}
        onMouseDown={rec_edit_toggle_handle}
        onMouseEnter={rec_mouse_enter_handle}>
        {rec_track.id}
      </Button>
      <Button type="text" style={{width: '36px', color: 'white'}}
        onMouseDown={cmd_toggle_lock} icon={<LockOutlined />} />
      {is_allow_solo_mute && is_audio_track && <Button type="text"
        style={{width: '36px', color: 'white', padding: 0,
          background: rec_track.solo.v && rec_track.solo.color}}
        onMouseDown={solo_handle}>
        S
      </Button>}
      {is_allow_solo_mute && is_audio_track && <Button type="text"
        style={{width: '36px', color: 'white', padding: 0,
          background: rec_track.mute.v && rec_track.mute.color}}
        onMouseDown={mute_handle}>
        M
      </Button>}
    </div>
  );
});
let Tracks_left_container = React.memo(({cmd_mute, cmd_solo, cmd_toggle_lock,
  f2px_k, lbin, scroll_x, scroll_x_set, scroll_y, scroll_y_set,
  tracks_container_height, tracks_pad, tracks_total_height,
  tracks_wrapper_height, tracks_wrapper_width, zoom})=>{
  let container_ref = useRef(null);
  let wheel_handle = useCallback(e=>{
    if (lbin?.rec_monitor_in?.len === undefined)
      return;
    let delta_x = e.ctrlKey ? e.deltaY : e.deltaX;
    let delta_y = e.ctrlKey ? e.deltaX : e.deltaY;
    let can_move_x;
    if (e.deltaX > 0)
    {
      can_move_x = scroll_x
        + player.px2f(tracks_wrapper_width, f2px_k, zoom)
        < lbin.rec_monitor_in.len;
    }
    else
      can_move_x = !!scroll_x;
    let can_move_y = e.deltaY > 0
      ? scroll_y + tracks_wrapper_height < tracks_container_height : !!scroll_y;
    if (delta_x && can_move_x || delta_y && can_move_y)
      e.preventDefault();
    scroll_x_set(scroll_x + player.px2f(delta_x, f2px_k, zoom));
    scroll_y_set(scroll_y + delta_y);
  }, [f2px_k, lbin?.rec_monitor_in?.len, scroll_x, scroll_x_set, scroll_y,
    scroll_y_set, tracks_container_height, tracks_wrapper_height,
    tracks_wrapper_width, zoom]);
  useEffect(()=>{
    let target = container_ref.current;
    if (!target)
      return;
    target.addEventListener('wheel', wheel_handle,
      {passive: false});
    return ()=>{
      target.removeEventListener('wheel', wheel_handle);
    };
  }, [wheel_handle]);
  return (
    <div ref={container_ref} style={{position: 'relative'}}>
      <div style={{minWidth: is_allow_solo_mute ? '160px' : '88px',
        width: is_allow_solo_mute ? '160px' : '88px', height: '100%',
        position: 'relative', overflow: 'hidden'}}>
        <div style={{height: `${tracks_total_height + tracks_pad * 2}px`,
          display: 'flex', flexDirection: 'column', alignItems: 'flex-end',
          justifyContent: 'center', position: 'absolute',
          top: `${tracks_pad - scroll_y}px`, right: 0}}>
          {lbin?.rec_monitor_in?.tracks?.map((track, idx)=>{
            if (track.type != 'timeline_track')
              return null;
            if (track.id[0] == 'V' && idx != 0)
              return null;
            return <Track_actions key={track.lbl} rec_track={track} lbin={lbin}
              cmd_toggle_lock={cmd_toggle_lock} cmd_solo={cmd_solo}
              cmd_mute={cmd_mute} />;
          })}
        </div>
      </div>
    </div>
  );
});
// XXX vladimir: move to player.js
let Frame_ptr = React.memo(({left, style={}})=>{
  return (
    <div
      style={{height: '100%', width: '1px', background: cyan[4],
        position: 'absolute', left: `${left}px`, pointerEvents: 'none',
        top: '0px', zIndex: 102, ...style}}
    />
  );
});
let Seg = React.memo(({editrate, start_tc, track_idx, abs_start, seg, fps, zoom,
  depth=1, is_no_border, ctx_data_set, f2px_k, lbin_set, track_id,
  premium_modal_open, lbin, cmd_trim, playback_rate=1})=>{
  let [is_left_edge_dragging, is_left_edge_dragging_set] = useState(false);
  let [is_right_edge_dragging, is_right_edge_dragging_set] = useState(false);
  let [start, start_set] = useState(player.frame_start(seg));
  let [len, len_set] = useState(player.seg_len(seg));
  let [last_start, last_start_set] = useState(player.frame_start(seg));
  let [last_len, last_len_set] = useState(player.seg_len(seg));
  let [last_left, last_left_set] = useState();
  let [last_right, last_right_set] = useState();
  let ref = useRef(null);
  let ctx_menu_handle = useCallback(e=>{
    e.preventDefault();
    let is_audio_track = track_id[0] == 'A';
    if (!seg.is_on_right_click_menu || is_audio_track)
      return;
    let items = seg.arr
      .map(child_seg=>{
        let src_seg = src_seg_get(child_seg);
        if (!src_seg)
          return null;
        return player.src2url(src_seg.src);
      })
      .filter(src=>src);
    ctx_data_set({visible: true, track_id, seg, items});
  }, [seg, ctx_data_set, track_id]);
  let child_segs = useMemo(()=>{
    if (seg.is_no_arr)
      return [];
    if (seg.type == 'selector')
      return [seg.arr[seg.select_idx]];
    return seg.arr;
  }, [seg]);
  let color = useMemo(()=>{
    if (seg.is_hide)
      return 'transparent';
    if (seg.rndr?.is_mute)
      return '#848484';
    if (seg.color)
      return seg.color;
    return '#222222';
  }, [seg]);
  let show_icons_container = useMemo(()=>{
    return seg.is_icon_purple || seg.is_icon_gray || seg.is_icon_motion;
  }, [seg.is_icon_gray, seg.is_icon_motion, seg.is_icon_purple]);
  let is_resizable = useMemo(()=>{
    return seg.type == 'source_clip' && seg.is_no_arr && !!cmd_trim;
  }, [cmd_trim, seg.is_no_arr, seg.type]);
  let mouse_move_handle = useCallback(e=>{
    if (is_left_edge_dragging)
    {
      let delta = player.px2f(e.clientX - last_left, f2px_k, zoom);
      start_set(last_start + delta);
      len_set(last_len - delta);
    }
    else if (is_right_edge_dragging)
    {
      let delta = player.px2f(e.clientX - last_right, f2px_k, zoom);
      start_set(last_start);
      len_set(last_len + delta / playback_rate);
    }
  }, [is_left_edge_dragging, is_right_edge_dragging, last_left, f2px_k, zoom,
    last_start, last_len, last_right, playback_rate]);
  let mouse_up_handle = useCallback(()=>{
    if (is_left_edge_dragging && cmd_trim)
    {
      cmd_trim(lbin.rec_monitor_in.mob_id, track_id, last_start,
        Math.round(start));
    }
    else if (is_right_edge_dragging && cmd_trim)
    {
      cmd_trim(lbin.rec_monitor_in.mob_id, track_id, last_start + last_len,
        Math.round(start + len));
    }
    is_left_edge_dragging_set(false);
    is_right_edge_dragging_set(false);
  }, [cmd_trim, is_left_edge_dragging, is_right_edge_dragging, last_len,
    last_start, len, start, track_id, lbin]);
  useEffect(()=>{
    if (!is_left_edge_dragging && !is_right_edge_dragging)
      return;
    document.addEventListener('mousemove', mouse_move_handle);
    document.addEventListener('mouseup', mouse_up_handle);
    return ()=>{
      document.removeEventListener('mousemove', mouse_move_handle);
      document.removeEventListener('mouseup', mouse_up_handle);
    };
  }, [is_left_edge_dragging, is_right_edge_dragging, mouse_move_handle,
    mouse_up_handle]);
  useEffect(()=>{
    start_set(player.frame_start(seg));
  }, [seg, seg.start]);
  useEffect(()=>{
    len_set(player.seg_len(seg));
  }, [seg, seg.len]);
  let resize_handler_get = useCallback(edge=>{
    return e=>{
      if (!cmd_trim)
        return;
      e.stopPropagation();
      let target = ref.current;
      if (!target)
        return;
      last_start_set(seg.start);
      last_len_set(seg.len);
      last_left_set(coords_get(target).left);
      last_right_set(coords_get(target).right);
      if (edge == 'left')
        is_left_edge_dragging_set(true);
      else if (edge == 'right')
        is_right_edge_dragging_set(true);
    };
  }, [cmd_trim, seg.len, seg.start]);
  let left = useMemo(()=>{
    return player.f2px(start, f2px_k, zoom);
  }, [f2px_k, start, zoom]);
  let width = useMemo(()=>{
    return player.f2px(len, f2px_k, zoom) * playback_rate;
  }, [f2px_k, len, playback_rate, zoom]);
  let next_playback_rate = useMemo(()=>{
    if (seg.type == 'operation_motion' && seg.playrate !== undefined)
      return playback_rate / seg.playrate;
    return playback_rate;
  }, [playback_rate, seg.playrate, seg.type]);
  let is_dragging = useMemo(()=>{
    return is_left_edge_dragging || is_right_edge_dragging;
  }, [is_left_edge_dragging, is_right_edge_dragging]);
  if (seg.type == 'marker')
  {
    return (
      <Marker_seg color={seg.color} left={left}
        zidx={seg.zidx} opacity={seg.opacity} />
    );
  }
  if (seg.type == 'mark_in')
    return <Mark_in_seg left={left} zidx={seg.zidx} opacity={seg.opacity} />;
  if (seg.type == 'mark_out')
    return <Mark_out_seg left={left} zidx={seg.zidx} opacity={seg.opacity} />;
  return (
    <>
      <div style={{display: 'flex', top: 0, position: 'absolute',
        height: '100%', borderRadius: '4px', whiteSpace: 'nowrap',
        left: `${left}px`, width: `${width}px`, background: color,
        overflow: 'hidden', zIndex: is_dragging ? 101 : seg.zidx,
        opacity: seg.opacity}} onContextMenu={ctx_menu_handle} ref={ref}>
        {child_segs.map((child_seg, index)=>{
          return (
            <Seg key={index} editrate={editrate} start_tc={start_tc}
              track_idx={track_idx} track_id={track_id} lbin_set={lbin_set}
              abs_start={abs_start + player.frame_start(child_seg)}
              fps={fps} zoom={zoom} depth={depth + 1} lbin={lbin}
              is_no_border={is_no_border || seg.is_no_border} seg={child_seg}
              ctx_data_set={ctx_data_set} f2px_k={f2px_k}
              premium_modal_open={premium_modal_open} cmd_trim={cmd_trim}
              playback_rate={next_playback_rate} />
          );
        })}
        {is_resizable && <div style={{width: '8px', height: '100%',
          position: 'absolute', zIndex: 101, left: 0, cursor: 'w-resize',
          background: is_left_edge_dragging ? cyan.primary : 'none'}}
        onMouseDown={resize_handler_get('left')} />}
        {is_resizable && <div style={{width: '8px', height: '100%',
          position: 'absolute', zIndex: seg.zidx, right: 0, cursor: 'e-resize',
          background: is_right_edge_dragging ? cyan.primary : 'none'}}
        onMouseDown={resize_handler_get('right')} />}
        {seg.type != 'filler' && <div style={{zIndex: seg.zidx,
          background: '#00000010', width: '100%', height: '50%',
          pointerEvents: 'none'}} />}
        {seg.lbl && !seg.is_lbl_hide && <span style={{fontSize: 12,
          userSelect: 'none', zIndex: seg.zidx, pointerEvents: 'none',
          fontStyle: seg.is_lbl_italic ? 'italic' : 'normal',
          color: 'white', position: 'absolute', left: '2px', top: '2px'}}>
          {seg.lbl}
        </span>}
        {show_icons_container && <div style={{display: 'flex', top: '50%',
          position: 'absolute', left: '50%', transform: 'translate(-50%, -50%)',
          gap: '2px', zIndex: seg.zidx}}>
          {seg.is_icon_purple && <Icon component={Purple_icon} />}
          {seg.is_icon_gray && <Icon component={Gray_icon} />}
          {seg.is_icon_motion && <Icon component={Motion_icon} />}
        </div>}
        {!!seg.outsync && <div style={{position: 'absolute', bottom: 0,
          left: `${player.f2px(seg.outsync.start, f2px_k, zoom)}px`,
          width: `${player.f2px(seg.outsync.len, f2px_k, zoom)}px`,
          height: '16px', borderBottom: `2px solid ${seg.outsync.color}`,
          display: 'flex', justifyContent: 'flex-end', fontSize: '12px'}}>
          <span style={{userSelect: 'none'}}>{seg.outsync.v}</span>
        </div>}
        {!!seg.dup && <div style={{position: 'absolute', top: 0, height: '2px',
          left: `${player.f2px(seg.dup.start, f2px_k, zoom)}px`,
          width: `${player.f2px(seg.dup.len, f2px_k, zoom)}px`,
          background: seg.dup.color, borderBottom: '1px solid black'}} />}
      </div>
      {seg.is_match_cut && <div style={{position: 'absolute', top: '50%',
        left: `${left - 5}px`, zIndex: seg.zidx,
        transform: 'translateY(-50%)', width: '7px', height: '7px',
        borderTop: '3px solid #8A8AF7', borderBottom: '3px solid #8A8AF7'}} />}
    </>
  );
});
let Tc_lbl = React.memo(({tc_lbl, pos})=>{
  return (
    <div style={{position: 'absolute', left: `${pos}px`,
      transform: 'translateX(-50%)', height: '100%', fontSize: '12px',
      display: 'flex', flexDirection: 'column', alignItems: 'center',
      justifyContent: 'space-around'}}>
      <div style={{height: '2px', width: '2px', borderRadius: '50%',
        background: 'white'}} />
      <span style={{userSelect: 'none', color: 'white'}}>{tc_lbl}</span>
    </div>
  );
});
let Ticks = React.memo(({lbin, len, zoom, scroll_x, frames_gap, f2px_k})=>{
  let start_tc = useMemo(()=>{
    if (lbin?.rec_monitor_in?.start_tc === undefined)
      return 0;
    return lbin.rec_monitor_in.start_tc;
  }, [lbin?.rec_monitor_in?.start_tc]);
  let width = useMemo(()=>player.f2px(len, f2px_k, zoom), [f2px_k, len, zoom]);
  let tc_lbls = useMemo(()=>{
    if (!lbin?.rec_monitor_in?.editrate)
      return [];
    let _tc_lbls = [];
    let min_frame = scroll_x - scroll_x % frames_gap;
    let max_frame = Math.ceil(scroll_x + len);
    for (let frame = min_frame; frame <= max_frame; frame += frames_gap)
    {
      let sec = player.f2px(frame, f2px_k, zoom);
      let tc_lbl = tc.frame2tc(start_tc + frame, lbin.rec_monitor_in.editrate);
      _tc_lbls.push(<Tc_lbl key={frame} tc_lbl={tc_lbl} pos={sec} />);
    }
    return _tc_lbls;
  }, [f2px_k, frames_gap, lbin?.rec_monitor_in?.editrate, scroll_x, start_tc,
    zoom, len]);
  return (
    <div style={{position: 'relative', height: '26px',
      left: 0, width: `${width}px`, zIndex: 101}}>
      {tc_lbls}
    </div>
  );
});
let Track = React.memo(({idx, id, lbin, height, start, len, color, arr=[], fps,
  zoom, is_no_border, lbin_set, f2px_k, ctx_data_set,
  premium_modal_open, cmd_trim})=>{
  let root_seg = useMemo(()=>arr[0], [arr]);
  let no_fill = useMemo(()=>{
    return height ? height.endsWith('no_fill') : false;
  }, [height]);
  let left = useMemo(()=>{
    return player.f2px(start, f2px_k, zoom);
  }, [f2px_k, start, zoom]);
  let width = useMemo(()=>player.f2px(len, f2px_k, zoom), [f2px_k, len, zoom]);
  return (
    <div style={{position: 'relative', height: `${track_height2px(height)}px`,
      left: `${left}px`, width: `${width}px`, margin: no_fill && '3px 0',
      background: color, marginBottom: '4px'}}>
      {!!root_seg && <Seg editrate={lbin.rec_monitor_in.editrate} lbin={lbin}
        premium_modal_open={premium_modal_open} lbin_set={lbin_set} zoom={zoom}
        start_tc={lbin.rec_monitor_in.start_tc} track_idx={idx} track_id={id}
        abs_start={player.frame_start(root_seg)} seg={root_seg} fps={fps}
        is_no_border={is_no_border} ctx_data_set={ctx_data_set} f2px_k={f2px_k}
        cmd_trim={cmd_trim} />}
    </div>
  );
});
let Tracks_right_container = React.memo(({lbin, fps, zoom, f2px_k, scroll_x,
  scroll_x_set, scroll_y, scroll_y_set, tracks_total_height, tracks_pad,
  rec_frame, rec_frame_set, playback_rate_set, lbin_set, cmd_trim,
  tracks_container_width, is_v_scroll_visible, premium_modal_open,
  tracks_wrapper_width, tracks_wrapper_width_set, tracks_container_height,
  tracks_wrapper_height, tracks_wrapper_height_set, cuts, cmd_selector_set})=>{
  let [show_v_ptr, show_v_ptr_set] = useState(false);
  let [v_ptr_frame, v_ptr_frame_set] = useState(0);
  let [ptr_moving, ptr_moving_set] = useState(false);
  let [scrolling_left, scrolling_left_set] = useState(false);
  let [scrolling_right, scrolling_right_set] = useState(false);
  let [ctx_data, ctx_data_set] = useState({visible: false});
  let tracks_wrapper_ref = useRef(null);
  let tracks_container_ref = useRef(null);
  let mouse_enter_handle = useCallback(()=>show_v_ptr_set(true), []);
  let mouse_move_handle = useCallback(e=>{
    if (!lbin?.rec_monitor_in)
      return;
    let target = tracks_container_ref.current;
    let _v_ptr_frame = player.px2f(e.clientX - coords_get(target).left, f2px_k,
      zoom);
    _v_ptr_frame = xutil.clamp(_v_ptr_frame,
      lbin.rec_monitor_in.start, player.frame_end(lbin.rec_monitor_in));
    _v_ptr_frame = Math.floor(_v_ptr_frame);
    v_ptr_frame_set(_v_ptr_frame);
    show_v_ptr_set(_v_ptr_frame != rec_frame);
  }, [f2px_k, lbin?.rec_monitor_in, rec_frame, zoom]);
  let mouse_leave_handle = useCallback(()=>show_v_ptr_set(false), []);
  let mouse_down_handle = useCallback(e=>{
    if (e.nativeEvent.which != 1)
      return;
    let _rec_frame;
    if (e.ctrlKey)
      _rec_frame = editor.nearest_cut_get(cuts, v_ptr_frame);
    else
      _rec_frame = v_ptr_frame;
    ptr_moving_set(true);
    rec_frame_set(_rec_frame);
    show_v_ptr_set(v_ptr_frame != _rec_frame);
    playback_rate_set(0);
  }, [cuts, v_ptr_frame, rec_frame_set, playback_rate_set]);
  let mouse_up_handle = useCallback(()=>ptr_moving_set(false), []);
  let wheel_handle = useCallback(e=>{
    if (lbin?.rec_monitor_in?.len === undefined)
      return;
    let delta_x = e.ctrlKey ? e.deltaY : e.deltaX;
    let delta_y = e.ctrlKey ? e.deltaX : e.deltaY;
    let can_move_x;
    if (e.deltaX > 0)
    {
      can_move_x = scroll_x
        + player.px2f(tracks_wrapper_width, f2px_k, zoom)
        < lbin.rec_monitor_in.len;
    }
    else
      can_move_x = !!scroll_x;
    let can_move_y = e.deltaY > 0
      ? scroll_y + tracks_wrapper_height < tracks_container_height : !!scroll_y;
    if (delta_x && can_move_x || delta_y && can_move_y)
      e.preventDefault();
    scroll_x_set(scroll_x + player.px2f(delta_x, f2px_k, zoom));
    scroll_y_set(scroll_y + delta_y);
  }, [f2px_k, lbin?.rec_monitor_in?.len, scroll_x, scroll_x_set, scroll_y,
    scroll_y_set, tracks_container_height, tracks_wrapper_height,
    tracks_wrapper_width, zoom]);
  let select_handle = useCallback(selector_idx=>{
    if (!lbin?.rec_monitor_in)
      return;
    cmd_selector_set(lbin.rec_monitor_in.mob_id, ctx_data.track_id,
      selector_idx, ctx_data.seg.abs_start);
    ctx_data_set({visible: false});
  }, [cmd_selector_set, ctx_data.seg, ctx_data.track_id, lbin]);
  let resize_handle = useCallback(()=>{
    let _tracks_wrapper_width = tracks_wrapper_ref.current.offsetWidth;
    let _tracks_wrapper_height = tracks_wrapper_ref.current.offsetHeight;
    tracks_wrapper_width_set(_tracks_wrapper_width);
    tracks_wrapper_height_set(_tracks_wrapper_height);
  }, [tracks_wrapper_height_set, tracks_wrapper_width_set]);
  let ptr_move_handle = useCallback(e=>{
    let target = tracks_container_ref.current;
    let _v_ptr_frame = player.px2f(e.clientX - coords_get(target).left, f2px_k,
      zoom);
    let _rec_frame;
    if (e.ctrlKey)
      _rec_frame = editor.nearest_cut_get(cuts, v_ptr_frame);
    else
      _rec_frame = _v_ptr_frame;
    rec_frame_set(_rec_frame);
    show_v_ptr_set(_v_ptr_frame != _rec_frame);
    playback_rate_set(0);
    let wrapper_coords = coords_get(tracks_wrapper_ref.current);
    if (e.clientX < wrapper_coords.left)
      scrolling_left_set(true);
    else
      scrolling_left_set(false);
    if (e.clientX > wrapper_coords.right - 40)
      scrolling_right_set(true);
    else
      scrolling_right_set(false);
  }, [f2px_k, zoom, cuts, v_ptr_frame, rec_frame_set, playback_rate_set]);
  let ptr_leave_handle = useCallback(()=>{
    ptr_moving_set(false);
    scrolling_left_set(false);
    scrolling_right_set(false);
  }, []);
  let frames_gap_get = useCallback(()=>{
    let min_gap_px = 120;
    let min_gap_s = player.px2f(min_gap_px, f2px_k, zoom) / fps;
    let periods_s = [0.04, 0.2, 1, 2, 5, 10, 15]; // 15 * 2 ^ n
    let min_period = periods_s.at(0);
    if (min_gap_s < periods_s.at(min_period))
    {
      let frames_gap = min_period * fps;
      return frames_gap;
    }
    let sec_gap = periods_s.find((period, index)=>{
      return min_gap_s >= periods_s[index - 1] && min_gap_s <= period;
    });
    if (sec_gap)
    {
      let frames_gap = sec_gap * fps;
      return frames_gap;
    }
    let base_period = periods_s.at(-1);
    let power = Math.floor(Math.log2(2 * min_gap_s / base_period));
    sec_gap = base_period * 2 ** power;
    let frames_gap = sec_gap * fps;
    return frames_gap;
  }, [fps, zoom, f2px_k]);
  useEffect(()=>{
    if (!ptr_moving)
      return;
    document.addEventListener('mousemove', ptr_move_handle);
    document.addEventListener('mouseup', ptr_leave_handle);
    return ()=>{
      document.removeEventListener('mousemove', ptr_move_handle);
      document.removeEventListener('mouseup', ptr_leave_handle);
    };
  }, [ptr_leave_handle, ptr_move_handle, ptr_moving]);
  useEffect(resize_handle, [resize_handle, is_v_scroll_visible]);
  useEffect(()=>{
    resize_handle();
    window.addEventListener('resize', resize_handle);
    return ()=>window.removeEventListener('resize', resize_handle);
  }, [resize_handle, tracks_wrapper_width_set, zoom]);
  useEffect(()=>{
    let target = tracks_container_ref.current;
    target.addEventListener('wheel', wheel_handle,
      {passive: false});
    return ()=>{
      target.removeEventListener('wheel', wheel_handle);
    };
  }, [wheel_handle]);
  use_effect_eserf(()=>eserf(function* player_interval(){
    if (lbin?.rec_monitor_in?.start === undefined
      || lbin?.rec_monitor_in?.len === undefined)
    {
      return;
    }
    if (!scrolling_left && !scrolling_right)
      return;
    while (1)
    {
      let _rec_frame;
      if (scrolling_left)
        _rec_frame = rec_frame - 1;
      else if (scrolling_right)
        _rec_frame = rec_frame + 1;
      _rec_frame = Math.min(_rec_frame,
        player.frame_end(lbin.rec_monitor_in));
      rec_frame_set(_rec_frame);
      playback_rate_set(0);
      if (scrolling_left)
        scroll_x_set(_rec_frame);
      else if (scrolling_right)
        scroll_x_set(scroll_x + 1);
      yield eserf.sleep(50);
    }
  }), [rec_frame, rec_frame_set, scrolling_left, scroll_x_set,
    scrolling_right, scroll_x, lbin?.rec_monitor_in?.start,
    lbin?.rec_monitor_in?.len, tracks_wrapper_width, fps, zoom,
    playback_rate_set, lbin?.rec_monitor_in]);
  let frames_gap = useMemo(()=>{
    return frames_gap_get();
  }, [frames_gap_get]);
  return (
    <div style={{maxWidth: '100%', width: '100%', display: 'flex',
      flexDirection: 'column', height: '100%'}}>
      <Cameras_modal is_open={ctx_data.visible} video_srcs={ctx_data.items}
        current_time={ctx_data.seg ? player.f2sec(ctx_data.seg.start, fps)
          : null}
        on_close={()=>ctx_data_set({visible: false})}
        on_select={select_handle} />
      <div ref={tracks_wrapper_ref} style={{maxWidth: '100%', width: '100%',
        overflow: 'hidden', position: 'relative', zIndex: 0,
        height: '100%'}}>
        <div ref={tracks_container_ref} style={{
          width: `${tracks_container_width}px`, height: '100%',
          position: 'absolute', top: 0,
          left: `${-player.f2px(scroll_x, f2px_k, zoom)}px`}}
        onMouseEnter={mouse_enter_handle} onMouseLeave={mouse_leave_handle}
        onMouseMove={mouse_move_handle} onMouseDown={mouse_down_handle}
        onMouseUp={mouse_up_handle}>
          <Ticks lbin={lbin} zoom={zoom} scroll_x={scroll_x}
            len={player.px2f(tracks_container_width, f2px_k, zoom)}
            frames_gap={frames_gap} f2px_k={f2px_k} />
          <div style={{position: 'absolute', top: `${tracks_pad - scroll_y}px`,
            height: `${tracks_total_height + tracks_pad * 2}px`,
            display: 'flex', flexDirection: 'column', alignItems: 'flex-start',
            justifyContent: 'center', overflow: 'hidden'}}>
            {lbin?.rec_monitor_in?.tracks?.map((track, idx)=>{
              if (track.type != 'timeline_track')
                return null;
              if (track.id[0] == 'V' && idx != 0)
                return null;
              return (
                <Track idx={idx} key={track.lbl} lbin={lbin}
                  height={track.height} arr={track.arr}
                  start={track.start} len={track.len}
                  color={track.color} fps={fps} zoom={zoom} f2px_k={f2px_k}
                  scroll_x={scroll_x} frames_gap={frames_gap} id={track.id}
                  tracks_wrapper_width={tracks_wrapper_width}
                  ctx_data_set={ctx_data_set} lbin_set={lbin_set}
                  premium_modal_open={premium_modal_open} cmd_trim={cmd_trim} />
              );
            })}
          </div>
          {lbin?.rec_monitor_in && <Frame_ptr
            left={player.f2px(rec_frame, f2px_k, zoom)} />}
          {lbin?.rec_monitor_in && <Frame_ptr
            left={player.f2px(rec_frame + 1, f2px_k, zoom)}
            style={{background: 'none',
              borderRight: `1px dashed ${cyan[4]}`}} />}
          {lbin?.rec_monitor_in && show_v_ptr && <Frame_ptr
            left={player.f2px(v_ptr_frame, f2px_k, zoom)}
            style={{background: gray[5], opacity: 0.8}} />}
        </div>
      </div>
      <H_scroll_ctrl lbin={lbin} fps={fps} scroll_x={scroll_x}
        scroll_x_set={scroll_x_set} zoom={zoom}
        f2px_k={f2px_k} tracks_wrapper_width={tracks_wrapper_width}
        tracks_container_width={tracks_container_width}
        is_v_scroll_visible={is_v_scroll_visible} />
    </div>
  );
});
let Tracks_container = React.memo(({cmd_mute, cmd_selector_set, cmd_solo,
  cmd_toggle_lock, cmd_trim, cuts, f2px_k, fps, is_v_scroll_visible, lbin,
  lbin_set, playback_rate_set, premium_modal_open, rec_frame, rec_frame_set,
  scroll_x, scroll_x_set, tracks_container_height, tracks_container_width,
  tracks_pad, tracks_total_height, tracks_wrapper_height,
  tracks_wrapper_height_set, tracks_wrapper_width, tracks_wrapper_width_set,
  zoom})=>{
  let [scroll_y, scroll_y_set] = useState(0);
  let scroll_y_handle = useCallback(_scroll_y=>{
    let min_scroll = 0;
    let max_scroll = tracks_total_height + tracks_pad * 3 -
    tracks_wrapper_height;
    _scroll_y = xutil.clamp(_scroll_y, min_scroll, max_scroll);
    scroll_y_set(_scroll_y);
  }, [tracks_pad, tracks_total_height, tracks_wrapper_height]);
  let tracks_wrapper_height_handle = useCallback(_tracks_wrapper_height=>{
    let min_scroll = 0;
    let max_scroll = tracks_total_height + tracks_pad * 3 -
    _tracks_wrapper_height;
    let _scroll_y = xutil.clamp(scroll_y, min_scroll, max_scroll);
    scroll_y_set(_scroll_y);
    tracks_wrapper_height_set(_tracks_wrapper_height);
  }, [scroll_y, tracks_pad, tracks_total_height, tracks_wrapper_height_set]);
  return (
    <div style={{maxWidth: '100%', height: '100%', display: 'flex'}}>
      <Tracks_left_container cmd_mute={cmd_mute} cmd_solo={cmd_solo}
        cmd_toggle_lock={cmd_toggle_lock} f2px_k={f2px_k} lbin={lbin}
        scroll_x={scroll_x} scroll_x_set={scroll_x_set} scroll_y={scroll_y}
        scroll_y_set={scroll_y_set}
        tracks_container_height={tracks_container_height}
        tracks_pad={tracks_pad} tracks_total_height={tracks_total_height}
        tracks_wrapper_height={tracks_wrapper_height}
        tracks_wrapper_width={tracks_wrapper_width} zoom={zoom} />
      <Tracks_right_container lbin={lbin} fps={fps} zoom={zoom} f2px_k={f2px_k}
        scroll_x={scroll_x} scroll_x_set={scroll_x_set} scroll_y={scroll_y}
        scroll_y_set={scroll_y_handle} tracks_total_height={tracks_total_height}
        tracks_pad={tracks_pad} rec_frame={rec_frame}
        rec_frame_set={rec_frame_set} playback_rate_set={playback_rate_set}
        tracks_container_width={tracks_container_width} lbin_set={lbin_set}
        is_v_scroll_visible={is_v_scroll_visible}
        tracks_wrapper_width={tracks_wrapper_width}
        tracks_wrapper_width_set={tracks_wrapper_width_set}
        tracks_container_height={tracks_container_height}
        tracks_wrapper_height={tracks_wrapper_height}
        tracks_wrapper_height_set={tracks_wrapper_height_handle}
        cuts={cuts} cmd_selector_set={cmd_selector_set}
        premium_modal_open={premium_modal_open} cmd_trim={cmd_trim} />
      <V_scroll_ctrl visible={is_v_scroll_visible} lbin={lbin}
        scroll_y={scroll_y} scroll_y_set={scroll_y_set}
        tracks_total_height={tracks_total_height} tracks_pad={tracks_pad}
        tracks_wrapper_height={tracks_wrapper_height} />
    </div>
  );
});
let H_scroll_ctrl = React.memo(({lbin, scroll_x, scroll_x_set,
  tracks_wrapper_width, tracks_container_width, is_v_scroll_visible, f2px_k,
  zoom})=>{
  let [is_dragging, is_dragging_set] = useState(false);
  let [shift, shift_set] = useState(0);
  let [container_width, container_width_set] = useState();
  let container_ref = useRef(null);
  let pin_ref = useRef(null);
  let scroll_height = 14;
  let min_scroll = 0;
  let max_scroll = useMemo(()=>{
    if (lbin?.rec_monitor_in?.len === undefined)
      return 0;
    return lbin.rec_monitor_in.len
    - player.px2f(tracks_wrapper_width, f2px_k, zoom);
  }, [f2px_k, lbin?.rec_monitor_in?.len, tracks_wrapper_width, zoom]);
  let min_pin_width = 25;
  let pin_width = container_width * tracks_wrapper_width
    / tracks_container_width;
  pin_width = Math.max(pin_width, min_pin_width);
  let available_space = container_width - pin_width;
  let mouse_down_handle = useCallback(e=>{
    is_dragging_set(true);
    shift_set(e.clientX - coords_get(pin_ref.current).left);
  }, []);
  let mouse_move_handle = useCallback(e=>{
    let container = pin_ref.current.parentElement;
    let container_offset = container.offsetLeft;
    let scroll_x_rel = (e.clientX - container_offset - shift)
      / available_space;
    scroll_x_rel = isFinite(scroll_x_rel) ? scroll_x_rel : 0;
    let _scroll_x = min_scroll + max_scroll * scroll_x_rel;
    scroll_x_set(_scroll_x);
  }, [shift, available_space, scroll_x_set, min_scroll, max_scroll]);
  let mouse_up_handle = useCallback(()=>{
    is_dragging_set(false);
  }, []);
  let window_resize_handle = useCallback(()=>{
    container_width_set(container_ref.current?.offsetWidth || 0);
  }, []);
  useEffect(window_resize_handle, [is_v_scroll_visible, window_resize_handle]);
  useEffect(()=>{
    window_resize_handle();
    window.addEventListener('resize', window_resize_handle);
    return ()=>window.removeEventListener('resize', window_resize_handle);
  }, [window_resize_handle]);
  useEffect(()=>{
    if (!is_dragging)
      return;
    document.addEventListener('mousemove', mouse_move_handle);
    document.addEventListener('mouseup', mouse_up_handle);
    return ()=>{
      document.removeEventListener('mousemove', mouse_move_handle);
      document.removeEventListener('mouseup', mouse_up_handle);
    };
  }, [is_dragging, mouse_move_handle, mouse_up_handle]);
  let left = scroll_x * available_space / max_scroll || 0;
  return (
    <div style={{display: 'flex', width: '100%', height: `${scroll_height}px`}}>
      <div ref={container_ref} style={{width: '100%', position: 'relative'}}>
        <div ref={pin_ref} style={{position: 'absolute', left: `${left}px`,
          background: '#222', borderRadius: '20px', cursor: 'pointer',
          width: `${pin_width}px`, height: '100%',
          display: !available_space && 'none'}}
        onMouseDown={mouse_down_handle} />
      </div>
    </div>
  );
});
let V_scroll_ctrl = React.memo(({visible, scroll_y, scroll_y_set,
  tracks_total_height, tracks_pad, tracks_wrapper_height})=>{
  let [is_dragging, is_dragging_set] = useState(false);
  let [shift, shift_set] = useState(0);
  let [container_height, container_height_set] = useState(0);
  let container_ref = useRef(null);
  let pin_ref = useRef(null);
  let scroll_width = 14;
  let min_scroll = 0;
  let max_scroll = tracks_total_height + tracks_pad * 3 - tracks_wrapper_height;
  let min_pin_height = 25;
  let pin_height = container_height
    * (tracks_wrapper_height - tracks_pad)
    / (tracks_total_height + tracks_pad * 2);
  pin_height = Math.max(pin_height, min_pin_height);
  let available_space = container_height - pin_height;
  let mouse_down_handle = useCallback(e=>{
    is_dragging_set(true);
    shift_set(e.clientY - coords_get(pin_ref.current).top + window.scrollY);
  }, []);
  let mouse_move_handle = useCallback(e=>{
    let container = pin_ref.current.parentElement;
    let container_offset = coords_get(container).top - window.scrollY;
    let scroll_y_rel = (e.clientY - container_offset - shift)
      / available_space;
    scroll_y_rel = isFinite(scroll_y_rel) ? scroll_y_rel : 0;
    let _scroll_y = min_scroll + max_scroll * scroll_y_rel;
    scroll_y_set(_scroll_y);
  }, [shift, available_space, scroll_y_set, min_scroll, max_scroll]);
  let mouse_up_handle = useCallback(()=>{
    is_dragging_set(false);
  }, []);
  let window_resize_handle = useCallback(()=>{
    container_height_set(container_ref.current?.offsetHeight || 0);
  }, []);
  useEffect(()=>{
    window_resize_handle();
    window.addEventListener('resize', window_resize_handle);
    return ()=>window.removeEventListener('resize', window_resize_handle);
  }, [window_resize_handle]);
  useEffect(window_resize_handle, [window_resize_handle, visible]);
  useEffect(()=>{
    if (!is_dragging)
      return;
    document.addEventListener('mousemove', mouse_move_handle);
    document.addEventListener('mouseup', mouse_up_handle);
    return ()=>{
      document.removeEventListener('mousemove', mouse_move_handle);
      document.removeEventListener('mouseup', mouse_up_handle);
    };
  }, [is_dragging, mouse_move_handle, mouse_up_handle]);
  let top = scroll_y * available_space / max_scroll || 0;
  if (!visible)
    return null;
  return (
    <div style={{display: 'flex', flexDirection: 'column', height: '100%',
      width: `${scroll_width}px`}}>
      <div ref={container_ref} style={{height: '100%', width: '100%',
        position: 'relative'}}>
        <div ref={pin_ref} style={{position: 'absolute', top: `${top}px`,
          background: '#222', borderRadius: '20px', cursor: 'pointer',
          width: '100%', height: `${pin_height}px`}}
        onMouseDown={mouse_down_handle} />
      </div>
    </div>
  );
});
let tracks_container_pad = 26;
let Timeline_panel = React.memo(({lbin, zoom: ext_zoom, on_zoom_change,
  scroll_x: ext_scroll_x, on_scroll_x_change, focused_panel, on_lbin_change,
  tracks_wrapper_width: ext_tracks_wrapper_width, token,
  on_tracks_wrapper_width_change, go_to_prev_cut, go_to_next_cut,
  tracks_wrapper_height: ext_tracks_wrapper_height,
  on_tracks_wrapper_height_change, rec_frame: ext_rec_frame,
  on_rec_frame_change, rec_playback_rate: ext_rec_playback_rate,
  on_rec_playback_rate_change, premium_modal_open,
  cmd_mark_in, cmd_mark_out, cmd_cut, cmd_mark_clip, cmd_clear_both_marks,
  cmd_lift, cmd_extract, cmd_selector_set, cmd_solo, cmd_mute,
  marker_add, play_in_to_out,
  cmd_toggle_lock, cmd_trim, ...rest})=>{
  let is_zoom_controlled = useMemo(()=>{
    return typeof ext_zoom != 'undefined';
  }, [ext_zoom]);
  let [int_zoom, int_zoom_set] = useState(is_zoom_controlled ? ext_zoom : 0);
  let zoom = useMemo(()=>{
    return is_zoom_controlled ? ext_zoom : int_zoom;
  }, [ext_zoom, int_zoom, is_zoom_controlled]);
  let is_scroll_x_controlled = useMemo(()=>{
    return typeof ext_scroll_x != 'undefined';
  }, [ext_scroll_x]);
  let [int_scroll_x, int_scroll_x_set] = useState(
    is_scroll_x_controlled ? ext_zoom : 0);
  let scroll_x = useMemo(()=>{
    return is_scroll_x_controlled ? ext_scroll_x : int_scroll_x;
  }, [ext_scroll_x, int_scroll_x, is_scroll_x_controlled]);
  let is_tracks_wrapper_width_controlled = useMemo(()=>{
    return typeof ext_tracks_wrapper_width != 'undefined';
  }, [ext_tracks_wrapper_width]);
  let [int_tracks_wrapper_width, int_tracks_wrapper_width_set] = useState(
    is_tracks_wrapper_width_controlled ? ext_tracks_wrapper_width :
      undefined);
  let tracks_wrapper_width = useMemo(()=>{
    return is_tracks_wrapper_width_controlled
      ? ext_tracks_wrapper_width : int_tracks_wrapper_width;
  }, [ext_tracks_wrapper_width, int_tracks_wrapper_width,
    is_tracks_wrapper_width_controlled]);
  let is_tracks_wrapper_height_controlled = useMemo(()=>{
    return typeof ext_tracks_wrapper_height != 'undefined';
  }, [ext_tracks_wrapper_height]);
  let [int_tracks_wrapper_height, int_tracks_wrapper_height_set] = useState(
    is_tracks_wrapper_height_controlled ? ext_tracks_wrapper_height :
      undefined);
  let tracks_wrapper_height = useMemo(()=>{
    return is_tracks_wrapper_height_controlled
      ? ext_tracks_wrapper_height : int_tracks_wrapper_height;
  }, [ext_tracks_wrapper_height, int_tracks_wrapper_height,
    is_tracks_wrapper_height_controlled]);
  let is_rec_frame_controlled = useMemo(()=>{
    return typeof ext_rec_frame != 'undefined';
  }, [ext_rec_frame]);
  let [int_rec_frame, int_rec_frame_set] = useState(
    is_rec_frame_controlled ? ext_rec_frame : 0);
  let rec_frame = useMemo(()=>{
    return is_rec_frame_controlled ? ext_rec_frame : int_rec_frame;
  }, [ext_rec_frame, int_rec_frame, is_rec_frame_controlled]);
  let is_rec_playback_rate_controlled = useMemo(()=>{
    return typeof ext_rec_playback_rate != 'undefined';
  }, [ext_rec_playback_rate]);
  let [int_rec_playback_rate, int_rec_playback_rate_set] = useState(
    is_rec_playback_rate_controlled ? ext_rec_playback_rate : 0);
  let rec_playback_rate = useMemo(()=>{
    return is_rec_playback_rate_controlled
      ? ext_rec_playback_rate : int_rec_playback_rate;
  }, [ext_rec_playback_rate, int_rec_playback_rate,
    is_rec_playback_rate_controlled]);
  let fps = useMemo(()=>{
    if (!lbin?.rec_monitor_in?.editrate?.d
      || !lbin?.rec_monitor_in?.editrate?.n)
    {
      return 0;
    }
    return lbin.rec_monitor_in.editrate.n / lbin.rec_monitor_in.editrate.d;
  }, [lbin?.rec_monitor_in?.editrate?.d, lbin?.rec_monitor_in?.editrate?.n]);
  let f2px_k = useMemo(()=>{
    if (lbin?.rec_monitor_in?.len === undefined)
      return 0;
    if (lbin.rec_monitor_in.len == 0)
      return 1;
    return lbin.rec_monitor_in.len / tracks_wrapper_width;
  }, [lbin?.rec_monitor_in?.len, tracks_wrapper_width]);
  let tracks_container_width = useMemo(()=>{
    if (lbin?.rec_monitor_in?.len === undefined)
      return 0;
    return Math.max(player.f2px(lbin.rec_monitor_in.len, f2px_k, zoom),
      tracks_wrapper_width);
  }, [f2px_k, lbin?.rec_monitor_in?.len, tracks_wrapper_width, zoom]);
  let tracks_total_height = useMemo(()=>{
    if (!lbin?.rec_monitor_in?.tracks)
      return 0;
    let tracks_height = editor.tracks_total_height_get(
      lbin.rec_monitor_in.tracks);
    let gaps_height = (lbin.rec_monitor_in.tracks.length - 1) * 4;
    return tracks_height + gaps_height;
  }, [lbin?.rec_monitor_in?.tracks]);
  let tracks_container_height = useMemo(()=>{
    return tracks_total_height + tracks_container_pad * 3;
  }, [tracks_total_height]);
  let is_v_scroll_visible = useMemo(()=>{
    return tracks_wrapper_height <= tracks_container_height;
  }, [tracks_container_height, tracks_wrapper_height]);
  let cuts = useMemo(()=>{
    if (!lbin?.rec_monitor_in?.tracks)
      return [];
    return editor.cuts_get(lbin.rec_monitor_in.tracks);
  }, [lbin?.rec_monitor_in?.tracks]);
  let markers_pos = useMemo(()=>{
    if (!lbin?.rec_monitor_in?.tracks)
      return [];
    return editor.markers_pos_get(lbin.rec_monitor_in.tracks);
  }, [lbin?.rec_monitor_in?.tracks]);
  let [, rec_editing_tracks_set] = use_je('highlighter.rec_editing_tracks',
    []);
  let zoom_change_handle = useCallback(_zoom=>{
    if (lbin?.rec_monitor_in?.len === undefined)
      return;
    if (on_zoom_change)
      on_zoom_change(_zoom);
    if (!is_zoom_controlled)
      int_zoom_set(_zoom);
    let _scroll_x = rec_frame
      - player.px2f(tracks_wrapper_width, f2px_k, _zoom) / 2;
    let min_scroll = 0;
    let max_scroll = lbin.rec_monitor_in.len
      - player.px2f(tracks_wrapper_width, f2px_k, _zoom);
    _scroll_x = xutil.clamp(_scroll_x, min_scroll, max_scroll);
    if (on_scroll_x_change)
      on_scroll_x_change(_scroll_x);
    if (!is_scroll_x_controlled)
      int_scroll_x_set(_scroll_x);
  }, [on_zoom_change, is_zoom_controlled, rec_frame, tracks_wrapper_width,
    f2px_k, lbin?.rec_monitor_in?.len, on_scroll_x_change,
    is_scroll_x_controlled]);
  let scroll_x_change_handle = useCallback(_scroll_x=>{
    if (lbin?.rec_monitor_in?.len === undefined)
      return;
    let min_scroll = 0;
    let max_scroll = lbin.rec_monitor_in.len
      - player.px2f(tracks_wrapper_width, f2px_k, zoom);
    _scroll_x = xutil.clamp(_scroll_x, min_scroll, max_scroll);
    if (on_scroll_x_change)
      on_scroll_x_change(_scroll_x);
    if (!is_scroll_x_controlled)
      int_scroll_x_set(_scroll_x);
  }, [f2px_k, is_scroll_x_controlled, lbin?.rec_monitor_in?.len,
    on_scroll_x_change, tracks_wrapper_width, zoom]);
  let tracks_wrapper_width_handle = useCallback(_tracks_wrapper_width=>{
    if (lbin?.rec_monitor_in?.len === undefined)
      return;
    let _zoom = Math.max(zoom, min_zoom);
    if (on_zoom_change)
      on_zoom_change(_zoom);
    if (!is_zoom_controlled)
      int_zoom_set(_zoom);
    let min_scroll = 0;
    let max_scroll = lbin.rec_monitor_in.len
      - player.px2f(_tracks_wrapper_width, f2px_k, _zoom);
    let _scroll_x = xutil.clamp(scroll_x, min_scroll, max_scroll);
    if (on_scroll_x_change)
      on_scroll_x_change(_scroll_x);
    if (!is_scroll_x_controlled)
      int_scroll_x_set(_scroll_x);
    if (on_tracks_wrapper_width_change)
      on_tracks_wrapper_width_change(_tracks_wrapper_width);
    if (!is_tracks_wrapper_width_controlled)
      int_tracks_wrapper_width_set(_tracks_wrapper_width);
  }, [f2px_k, is_scroll_x_controlled, is_tracks_wrapper_width_controlled,
    is_zoom_controlled, lbin?.rec_monitor_in?.len, on_scroll_x_change,
    on_tracks_wrapper_width_change, on_zoom_change, scroll_x, zoom]);
  let tracks_wrapper_height_handle = useCallback(_tracks_wrapper_height=>{
    if (on_tracks_wrapper_height_change)
      on_tracks_wrapper_height_change(_tracks_wrapper_height);
    if (!is_tracks_wrapper_height_controlled)
      int_tracks_wrapper_height_set(_tracks_wrapper_height);
  }, [is_tracks_wrapper_height_controlled, on_tracks_wrapper_height_change]);
  let rec_playback_rate_handle = useCallback(_rec_playback_rate=>{
    if (on_rec_playback_rate_change)
      on_rec_playback_rate_change(_rec_playback_rate);
    if (!is_rec_playback_rate_controlled)
      int_rec_playback_rate_set(_rec_playback_rate);
  }, [is_rec_playback_rate_controlled, on_rec_playback_rate_change]);
  let rec_frame_handle = useCallback((_rec_frame, ignore_clamp, scroll)=>{
    if (lbin?.rec_monitor_in?.start === undefined
      || lbin?.rec_monitor_in?.len === undefined)
    {
      return;
    }
    _rec_frame = Math.floor(_rec_frame);
    if (!ignore_clamp)
    {
      let min_frame = lbin.rec_monitor_in.start;
      let max_frame = lbin.rec_monitor_in.len;
      _rec_frame = xutil.clamp(_rec_frame, min_frame, max_frame);
    }
    if (on_rec_frame_change)
      on_rec_frame_change(_rec_frame);
    if (!is_rec_frame_controlled)
      int_rec_frame_set(_rec_frame);
    if (!scroll)
      return;
    let frames_visible = player.px2f(tracks_wrapper_width, f2px_k, zoom);
    if (_rec_frame < scroll_x || _rec_frame > scroll_x + frames_visible)
      scroll_x_change_handle(_rec_frame - frames_visible / 2);
  }, [f2px_k, is_rec_frame_controlled, lbin?.rec_monitor_in?.start,
    lbin?.rec_monitor_in?.len, on_rec_frame_change, scroll_x,
    scroll_x_change_handle, tracks_wrapper_width, zoom]);
  let rec_playing_toggle = useCallback(()=>{
    rec_playback_rate_handle(rec_playback_rate ? 0 : 1);
  }, [rec_playback_rate_handle, rec_playback_rate]);
  let play_backwards_handle = useCallback(()=>{
    // XXX vladimir: move playback rate progression to comp.js
    let playback_rates = [-8, -5, -3, -2, -1, 0];
    if (rec_playback_rate > 0)
      return rec_playback_rate_handle(playback_rates.at(-2));
    let idx = playback_rates.indexOf(rec_playback_rate);
    let _playback_rate = playback_rates[idx - 1];
    if (!_playback_rate)
      _playback_rate = playback_rates[0];
    rec_playback_rate_handle(_playback_rate);
  }, [rec_playback_rate, rec_playback_rate_handle]);
  let stop_handle = useCallback(()=>{
    rec_playback_rate_handle(0);
  }, [rec_playback_rate_handle]);
  let play_handle = useCallback(()=>{
    // XXX vladimir: move playback rate progression to comp.js
    let playback_rates = [0, 1, 2, 3, 5, 8];
    if (rec_playback_rate < 0)
      return rec_playback_rate_handle(playback_rates[0]);
    let idx = playback_rates.indexOf(rec_playback_rate);
    let _playback_rate = playback_rates[idx + 1];
    if (!_playback_rate)
      _playback_rate = playback_rates.at(-1);
    rec_playback_rate_handle(_playback_rate);
  }, [rec_playback_rate, rec_playback_rate_handle]);
  let go_to_prev_marker = useCallback(()=>{
    if (!markers_pos.length)
      return;
    let min_pos = Math.min(...markers_pos);
    if (rec_frame <= min_pos)
      return;
    let max_pos = Math.max(...markers_pos);
    let _rec_frame;
    if (rec_frame > max_pos)
      _rec_frame = max_pos;
    else
    {
      _rec_frame = markers_pos.find((marker, index)=>{
        return marker < rec_frame && rec_frame <= markers_pos[index + 1];
      });
    }
    rec_frame_handle(_rec_frame, false, true);
  }, [markers_pos, rec_frame_handle, rec_frame]);
  let go_to_next_marker = useCallback(()=>{
    if (!markers_pos.length)
      return;
    let _rec_frame = markers_pos.find(marker=>rec_frame < marker);
    if (!_rec_frame)
      _rec_frame = markers_pos.at(-1);
    rec_frame_handle(_rec_frame, false, true);
  }, [markers_pos, rec_frame_handle, rec_frame]);
  let go_to_mark_in = useCallback(()=>{
    if (!lbin?.rec_monitor_in?.mark_in)
      return;
    rec_frame_handle(lbin.rec_monitor_in.mark_in, false, true);
  }, [lbin?.rec_monitor_in?.mark_in, rec_frame_handle]);
  let go_to_mark_out = useCallback(()=>{
    if (!lbin?.rec_monitor_in?.mark_out)
      return;
    rec_frame_handle(lbin.rec_monitor_in.mark_out, false, true);
  }, [lbin?.rec_monitor_in?.mark_out, rec_frame_handle]);
  let rec_move_frame = useCallback(frame_shift=>{
    let _rec_frame = rec_frame + frame_shift;
    rec_frame_handle(_rec_frame, false, true);
    rec_playback_rate_handle(0);
  }, [rec_frame, rec_frame_handle, rec_playback_rate_handle]);
  let zoom_step = 1;
  let zoom_out_handle = useCallback(()=>{
    let _zoom = Math.round(zoom - zoom_step);
    _zoom = xutil.clamp(_zoom, min_zoom, max_zoom);
    zoom_change_handle(_zoom);
  }, [zoom, zoom_change_handle, zoom_step]);
  let zoom_in_handle = useCallback(()=>{
    let _zoom = Math.round(zoom + zoom_step);
    _zoom = xutil.clamp(_zoom, min_zoom, max_zoom);
    zoom_change_handle(_zoom);
  }, [zoom, zoom_change_handle, zoom_step]);
  let mark_in = useCallback(()=>{
    if (!lbin)
      return;
    cmd_mark_in(lbin.rec_monitor_in.mob_id, rec_frame);
  }, [cmd_mark_in, lbin, rec_frame]);
  let mark_out = useCallback(()=>{
    if (!lbin)
      return;
    cmd_mark_out(lbin.rec_monitor_in.mob_id, rec_frame);
  }, [cmd_mark_out, lbin, rec_frame]);
  let mark_clip = useCallback(()=>{
    if (!lbin)
      return;
    cmd_mark_clip(lbin.rec_monitor_in.mob_id, rec_frame);
  }, [cmd_mark_clip, lbin, rec_frame]);
  let clear_both_marks = useCallback(()=>{
    if (!lbin)
      return;
    cmd_clear_both_marks(lbin.rec_monitor_in.mob_id);
  }, [cmd_clear_both_marks, lbin]);
  let cut = useCallback(()=>{
    if (!lbin)
      return;
    cmd_cut(lbin.rec_monitor_in.mob_id, rec_frame);
  }, [cmd_cut, lbin, rec_frame]);
  let lift = useCallback(()=>{
    if (!lbin?.rec_monitor_in?.mark_in || !lbin?.rec_monitor_in?.mark_out)
      return;
    cmd_lift(lbin.rec_monitor_in.mob_id, lbin.rec_monitor_in.mark_in,
      lbin.rec_monitor_in.mark_out);
  }, [cmd_lift, lbin]);
  let extract = useCallback(()=>{
    if (!lbin?.rec_monitor_in?.mark_in || !lbin?.rec_monitor_in?.mark_out)
      return;
    cmd_extract(lbin.rec_monitor_in.mob_id, lbin.rec_monitor_in.mark_in,
      lbin.rec_monitor_in.mark_out);
  }, [cmd_extract, lbin]);
  let selector_change = useCallback(shift=>{
    for (let track of lbin.rec_monitor_in.tracks)
    {
      let abs_starts_map = player.abs_starts_get(track);
      for (let [seg] of abs_starts_map.entries())
      {
        if (seg.type != 'selector' || seg.abs_start > rec_frame
          || seg.abs_start + seg.len < rec_frame)
        {
          continue;
        }
        let next_selector_idx = (seg.select_idx + shift) % seg.arr.length;
        if (next_selector_idx < 0)
          next_selector_idx += seg.arr.length;
        cmd_selector_set(lbin.rec_monitor_in.mob_id, track.id,
          next_selector_idx, seg.abs_start);
        return;
      }
    }
  }, [cmd_selector_set, lbin, rec_frame]);
  let mark_all_tracks = useCallback(()=>{
    if (!lbin?.rec_monitor_in)
      return;
    let _rec_editing_tracks = lbin.rec_monitor_in.tracks.map(track=>track.id);
    rec_editing_tracks_set(_rec_editing_tracks);
  }, [rec_editing_tracks_set, lbin]);
  let unmark_all_tracks = useCallback(()=>{
    rec_editing_tracks_set([]);
  }, [rec_editing_tracks_set]);
  let action2func = useMemo(()=>({
    zoom_in: zoom_in_handle,
    zoom_out: zoom_out_handle,
    play_stop: rec_playing_toggle,
    play_backwards: play_backwards_handle,
    stop: stop_handle,
    play: play_handle,
    go_to_prev_cut: go_to_prev_cut,
    go_to_next_cut: go_to_next_cut,
    go_to_prev_marker: go_to_prev_marker,
    go_to_next_marker: go_to_next_marker,
    go_to_mark_in: go_to_mark_in,
    go_to_mark_out: go_to_mark_out,
    mark_all_tracks,
    unmark_all_tracks,
    move_one_frame_left: ()=>{
      if (focused_panel && focused_panel != 'timeline')
        return;
      rec_move_frame(-1);
    },
    move_one_frame_right: ()=>{
      if (focused_panel && focused_panel != 'timeline')
        return;
      rec_move_frame(1);
    },
    move_ten_frames_left: ()=>{
      if (focused_panel && focused_panel != 'timeline')
        return;
      rec_move_frame(-10);
    },
    move_ten_frames_right: ()=>{
      if (focused_panel && focused_panel != 'timeline')
        return;
      rec_move_frame(10);
    },
    mark_in,
    mark_out,
    mark_clip,
    clear_both_marks,
    cut,
    lift,
    extract,
    marker_add: marker_add,
    play_in_to_out: play_in_to_out,
    change_selector_prev: ()=>selector_change(-1),
    change_selector_next: ()=>selector_change(1),
  }), [zoom_in_handle, zoom_out_handle, rec_playing_toggle, mark_all_tracks,
    play_backwards_handle, stop_handle, play_handle, go_to_prev_cut,
    go_to_next_cut, go_to_prev_marker, go_to_next_marker, go_to_mark_in,
    go_to_mark_out, mark_in, mark_out, mark_clip, selector_change,
    clear_both_marks, cut, lift, extract, marker_add,
    play_in_to_out, focused_panel, rec_move_frame, unmark_all_tracks]);
  useEffect(()=>{
    let unsubscribe = tinykeys(window, key_binding_map_get(action2func));
    return ()=>unsubscribe();
  }, [action2func]);
  return (
    <Editor_panel style={{flexDirection: 'column'}} {...rest}>
      <Tracks_container cmd_mute={cmd_mute} cmd_selector_set={cmd_selector_set}
        cmd_solo={cmd_solo} cmd_toggle_lock={cmd_toggle_lock}
        cmd_trim={cmd_trim} cuts={cuts} f2px_k={f2px_k} fps={fps}
        is_v_scroll_visible={is_v_scroll_visible} lbin={lbin}
        lbin_set={on_lbin_change} playback_rate_set={rec_playback_rate_handle}
        premium_modal_open={premium_modal_open} rec_frame={rec_frame}
        rec_frame_set={rec_frame_handle} scroll_x={scroll_x}
        scroll_x_set={scroll_x_change_handle}
        tracks_container_height={tracks_container_height}
        tracks_container_width={tracks_container_width}
        tracks_pad={tracks_container_pad}
        tracks_total_height={tracks_total_height}
        tracks_wrapper_height={tracks_wrapper_height}
        tracks_wrapper_height_set={tracks_wrapper_height_handle}
        tracks_wrapper_width={tracks_wrapper_width}
        tracks_wrapper_width_set={tracks_wrapper_width_handle} zoom={zoom} />
    </Editor_panel>
  );
});
let Video_thumbnail = React.memo(({src, time, on_click})=>{
  let {qs_o} = use_qs();
  let video_ref = useRef(null);
  let [is_loading, is_loading_set] = useState(true);
  let loaded_metadata_handle = useCallback(()=>{
    video_ref.current.currentTime = qs_o.dbg ? 0 : time;
  }, [qs_o.dbg, time]);
  let seeked_handle = useCallback(()=>{
    is_loading_set(false);
  }, []);
  let error_handle = useCallback(()=>{
    is_loading_set(false);
    video_ref.current.poster= media_offline_poster;
  }, []);
  useEffect(()=>{
    let video_el = video_ref.current;
    video_el.addEventListener('loadedmetadata', loaded_metadata_handle);
    video_el.addEventListener('seeked', seeked_handle);
    video_el.addEventListener('error', error_handle);
    return ()=>{
      video_el.removeEventListener('loadedmetadata', loaded_metadata_handle);
      video_el.removeEventListener('seeked', seeked_handle);
      video_el.removeEventListener('error', error_handle);
    };
  }, [error_handle, loaded_metadata_handle, qs_o.dbg, seeked_handle, time]);
  return (
    <div style={{width: 'calc(50% - 5px)', cursor: 'pointer',
      position: 'relative'}}>
      {is_loading && <div style={{position: 'absolute',
        width: '100%', height: '100%', zIndex: 9999, left: 0, top: 0,
        display: 'flex', flexDirection: 'column', pointerEvents: 'none',
        justifyContent: 'center', alignItems: 'center',
        background: '#ffffff50'}}>
        <Spin indicator={<LoadingOutlined spin
          style={{fontSize: '40px'}} />} />
      </div>}
      <video style={{width: '100%', height: '100%', objectFit: 'cover'}}
        src={src} onClick={on_click} preload="metadata" ref={video_ref} />
    </div>
  );
});
let Cameras_modal = React.memo(({is_open, on_close, on_select, current_time,
  video_srcs=[]})=>{
  let {t} = useTranslation();
  return (
    <Modal title={t('Cameras')} open={is_open} footer={null}
      onCancel={on_close}>
      <div style={{display: 'flex', flexWrap: 'wrap', gap: '5px',
        justifyContent: 'space-between'}}>
        {video_srcs.map((src, idx)=><Video_thumbnail key={src+current_time}
          src={src} time={current_time} on_click={()=>on_select(idx)} />)}
      </div>
    </Modal>
  );
});
let E = ()=>{
  let {t} = useTranslation();
  let {user, token, org, user_full} = auth.use_auth();
  let [message_api, message_ctx_holder] = message.useMessage();
  let [modal, modal_ctx_holder] = Modal.useModal();
  use_qs_clear({src: 1, src_path: 1, src_is_dir: 1, src_filename: 1});
  let {qs_o} = use_qs();
  let initial_src = useMemo(()=>qs_o.src, [qs_o.src]);
  let [is_rate, set_is_rate] = useState(false);
  let [is_premium_modal_open, is_premium_modal_open_set] = useState(false);
  let [lbin, lbin_set] = useState(initial_src ? null : lbin_demo.lbin);
  let [lbin_changes, lbin_changes_set] = useState([]);
  let [cur_lbin_change_idx, cur_lbin_change_idx_set] = useState(-1);
  let [aaf_in, aaf_in_set] = useState();
  let [etag, etag_set] = useState();
  let [rec_frame, rec_frame_set] = useState(0);
  let [zoom, zoom_set] = useState(0);
  let [scroll_x, scroll_x_set] = useState(0);
  let [tracks_wrapper_width, tracks_wrapper_width_set] = useState();
  let [focused_panel, focused_panel_set] = useState('timeline');
  let [max_playing_frame, max_playing_frame_set] = useState();
  let [is_initial_src_loading, is_initial_src_loading_set] = useState(false);
  let [are_render_segs_loading, are_render_segs_loading_set] = useState(false);
  let [loading_cmd, loading_cmd_set] = useState(null);
  let [can_play, can_play_set] = useState(true);
  let [rec_playback_rate_before_stopped,
    rec_playback_rate_before_stopped_set] = useState(0);
  let [rec_editing_tracks, rec_editing_tracks_set] = use_je(
    'highlighter.rec_editing_tracks', []);
  let fps = useMemo(()=>{
    if (!lbin?.rec_monitor_in?.editrate?.d
      || !lbin?.rec_monitor_in?.editrate?.n)
    {
      return 0;
    }
    return lbin.rec_monitor_in.editrate.n / lbin.rec_monitor_in.editrate.d;
  }, [lbin?.rec_monitor_in?.editrate?.d, lbin?.rec_monitor_in?.editrate?.n]);
  let f2px_k = useMemo(()=>{
    if (lbin?.rec_monitor_in?.len === undefined)
      return 0;
    if (lbin.rec_monitor_in.len == 0)
      return 1;
    return lbin.rec_monitor_in.len / tracks_wrapper_width;
  }, [lbin?.rec_monitor_in?.len, tracks_wrapper_width]);
  let [video_render_segs, video_render_segs_set] = useState([]);
  let [audio_render_segs, audio_render_segs_set] = useState([]);
  useEffect(()=>{
    if (!lbin?.rec_monitor_in || etag)
      return;
    let _rec_editing_tracks = lbin.rec_monitor_in.tracks
      .filter(track=>track.is_edit)
      .map(track=>track.id);
    rec_editing_tracks_set(_rec_editing_tracks);
  }, [etag, lbin, rec_editing_tracks_set]);
  use_effect_eserf(()=>eserf(function* _use_effect_render_segs_load(){
    if (!token || !lbin?.rec_monitor_in)
    {
      video_render_segs_set([]);
      audio_render_segs_set([]);
      return;
    }
    are_render_segs_loading_set(true);
    let first_track = lbin?.rec_monitor_in?.tracks[0];
    let [_video_render_segs, _audio_render_segs] = yield this.wait_ret([
      player.video_render_segs_get(token, lbin?.rec_monitor_in,
        {track_id: first_track.id, is_solo: false}),
      player.audio_render_segs_get(token, lbin?.rec_monitor_in,
        {track_id: first_track.id, is_solo: false}),
    ]);
    video_render_segs_set(_video_render_segs);
    audio_render_segs_set(_audio_render_segs);
    are_render_segs_loading_set(false);
  }), [lbin, token]);
  let [rec_playback_rate, rec_playback_rate_set] = useState(0);
  let editor_ref = useRef(null);
  let scroll_x_handle = useCallback(_scroll_x=>{
    if (lbin?.rec_monitor_in?.len === undefined)
      return;
    let min_scroll = 0;
    let max_scroll = lbin.rec_monitor_in.len
      - player.px2f(tracks_wrapper_width, f2px_k, zoom);
    _scroll_x = xutil.clamp(_scroll_x, min_scroll, max_scroll);
    scroll_x_set(_scroll_x);
  }, [f2px_k, lbin?.rec_monitor_in?.len, tracks_wrapper_width, zoom]);
  let rec_frame_handle = useCallback((_rec_frame, ignore_clamp, scroll,
    keep_max_playing_frame, ignore_playback_rate_before_stooped_reset)=>{
    if (lbin?.rec_monitor_in?.len === undefined
      || lbin?.rec_monitor_in?.start === undefined)
    {
      return;
    }
    _rec_frame = Math.floor(_rec_frame);
    if (!ignore_clamp)
    {
      let min_frame = lbin.rec_monitor_in.start;
      let max_frame = lbin.rec_monitor_in.len;
      _rec_frame = xutil.clamp(_rec_frame, min_frame, max_frame);
    }
    rec_frame_set(_rec_frame);
    if (!keep_max_playing_frame)
      max_playing_frame_set(undefined);
    if (!scroll)
      return;
    let frames_visible = player.px2f(tracks_wrapper_width, f2px_k, zoom);
    if (_rec_frame < scroll_x || _rec_frame > scroll_x + frames_visible)
      scroll_x_handle(_rec_frame - frames_visible / 2);
    if (!ignore_playback_rate_before_stooped_reset)
      rec_playback_rate_before_stopped_set(0);
  }, [f2px_k, lbin?.rec_monitor_in?.len, lbin?.rec_monitor_in?.start, scroll_x,
    scroll_x_handle, tracks_wrapper_width, zoom]);
  let zoom_change_handle = useCallback(_zoom=>{
    if (lbin?.rec_monitor_in?.len === undefined)
      return;
    zoom_set(_zoom);
    let _scroll_x = rec_frame
      - player.px2f(tracks_wrapper_width, f2px_k, _zoom) / 2;
    let min_scroll = 0;
    let max_scroll = lbin.rec_monitor_in.len
      - player.px2f(tracks_wrapper_width, f2px_k, _zoom);
    _scroll_x = xutil.clamp(_scroll_x, min_scroll, max_scroll);
    scroll_x_set(_scroll_x);
  }, [lbin?.rec_monitor_in?.len, rec_frame, tracks_wrapper_width, f2px_k]);
  let play_in_to_out = useCallback(()=>{
    if (!lbin?.rec_monitor_in)
      return;
    if (rec_playback_rate)
      return rec_playback_rate_set(0);
    if (lbin.rec_monitor_in.mark_in)
      rec_frame_set(lbin.rec_monitor_in.mark_in);
    rec_playback_rate_set(1);
    if (lbin.rec_monitor_in.mark_out)
      max_playing_frame_set(lbin.rec_monitor_in.mark_out);
  }, [lbin?.rec_monitor_in, rec_playback_rate]);
  let rec_playback_rate_handle = useCallback(_rec_playback_rate=>{
    if (!can_play)
      return;
    rec_playback_rate_set(_rec_playback_rate);
    rec_playback_rate_before_stopped_set(_rec_playback_rate);
    max_playing_frame_set(undefined);
  }, [can_play]);
  let rec_playing_ref = useRef(null);
  use_effect_eserf(()=>eserf(function* _use_effect_rec_frame_loop(){
    if (!lbin || !rec_playback_rate || !can_play)
    {
      rec_playing_ref.current = null;
      return;
    }
    if (!rec_playing_ref.current)
    {
      rec_playing_ref.current = {};
      rec_playing_ref.current.start_tc = performance.now();
      rec_playing_ref.current.start_frame = rec_frame;
      rec_playing_ref.current.playback_rate = rec_playback_rate;
    }
    if (rec_playback_rate != rec_playing_ref.current.playback_rate)
    {
      rec_playing_ref.current.start_tc = performance.now();
      rec_playing_ref.current.start_frame = rec_frame;
      rec_playing_ref.current.playback_rate = rec_playback_rate;
    }
    let frame_ms = xdate.MS_SEC / fps * 2;
    while (1)
    {
      let ms = (performance.now() - rec_playing_ref.current.start_tc)
        * rec_playback_rate;
      let sec = ms / xdate.MS_SEC;
      let _rec_frame = rec_playing_ref.current.start_frame +
        Math.floor(sec * fps);
      if (_rec_frame == rec_frame)
      {
        yield eserf.sleep(frame_ms);
        continue;
      }
      if (_rec_frame >= max_playing_frame)
      {
        rec_playback_rate_set(0);
        max_playing_frame_set(undefined);
        return ;
      }
      rec_frame_handle(_rec_frame, false, true, true, true);
      if (_rec_frame < 0 || _rec_frame == lbin.rec_monitor_in.len - 1)
        return rec_playback_rate_set(0);
      // Amount of frames in ms
      yield eserf.sleep(frame_ms);
    }
  }), [rec_frame, rec_frame_handle, fps, rec_playback_rate, max_playing_frame,
    lbin, scroll_x, scroll_x_handle, tracks_wrapper_width, f2px_k, can_play]);
  let rec_playing_toggle = useCallback(()=>{
    rec_playback_rate_set(rec_playback_rate ? 0 : 1);
  }, [rec_playback_rate]);
  let premium_modal_open = useCallback(()=>{
    is_premium_modal_open_set(true);
  }, []);
  let cuts = useMemo(()=>{
    if (!lbin?.rec_monitor_in?.tracks)
      return [];
    return editor.cuts_get(lbin.rec_monitor_in.tracks);
  }, [lbin?.rec_monitor_in?.tracks]);
  let go_to_prev_cut = useCallback(()=>{
    if (!cuts.length)
      return;
    let _rec_frame = cuts.find((cut, index)=>{
      return cut < rec_frame && rec_frame <= cuts[index + 1];
    });
    if (!_rec_frame)
      _rec_frame = cuts.at(0);
    rec_frame_handle(_rec_frame, false, true);
    rec_playback_rate_handle(0);
  }, [rec_playback_rate_handle, cuts, rec_frame, rec_frame_handle]);
  let go_to_next_cut = useCallback(()=>{
    if (!cuts.length)
      return;
    let _rec_frame = cuts.find(cut=>rec_frame < cut);
    if (!_rec_frame)
      _rec_frame = cuts.at(-1);
    rec_frame_handle(_rec_frame, false, true);
    rec_playback_rate_handle(0);
  }, [cuts, rec_frame, rec_frame_handle, rec_playback_rate_handle]);
  // XXX vladimir: import from editor.js
  // XXX vladimir: make cmd_eval global and wrapper (cmd2lbin)
  // XXX vladimir: handle multiple clicking (use sequence)
  let cmd_eval = useCallback((_aaf_in, cmd, data={}, lbl)=>eserf(function*
  _cmd_eval(){
    if (!_aaf_in)
      return {err: 'no aaf'};
    if (!cmd)
      assert(0, 'no cmd');
    if (!Array.isArray(data))
      data = [data];
    if (!data.length)
      assert(0, 'no data');
    if (lbl)
      loading_cmd_set(lbl);
    let res = yield back_app.editor.cmds(token, _aaf_in, cmd, data);
    loading_cmd_set(null);
    if (res.err)
      return {err: res.err};
    let rec_monitor_out = res.lbin.rec_monitor_out;
    rec_monitor_out = lbin_sync(lbin.rec_monitor_in, rec_monitor_out);
    let _lbin = {...res.lbin, rec_monitor_in: rec_monitor_out,
      src_monitor: res.lbin.src_monitor || lbin.src_monitor};
    lbin_set(_lbin);
    let _rec_editing_tracks = rec_editing_tracks
      .filter(track_id=>{
        return _lbin.rec_monitor_in.tracks.find(track=>track.id == track_id);
      });
    let diffs = deep_diff(lbin, _lbin);
    let lbin_change = {cmd, diffs, aaf_in: _aaf_in, etag};
    let _lbin_changes = lbin_changes.slice(0, cur_lbin_change_idx + 1);
    _lbin_changes.push(lbin_change);
    lbin_changes_set(_lbin_changes);
    cur_lbin_change_idx_set(cur_lbin_change_idx + 1);
    aaf_in_set(res.file);
    etag_set(res.etag);
    rec_editing_tracks_set(_rec_editing_tracks);
    return {aaf_in: res.file, etag: res.etag};
  }), [cur_lbin_change_idx, lbin, lbin_changes, token, etag,
    rec_editing_tracks, rec_editing_tracks_set]);
  let active_track_ids = useMemo(()=>{
    if (!lbin?.rec_monitor_in?.tracks)
      return [];
    return lbin.rec_monitor_in.tracks.map(track=>track.id);
  }, [lbin?.rec_monitor_in?.tracks]);
  let cmd_trim = useCallback((mob_id, track_id, abs_frame, abs_frame_set)=>{
    if (mob_id === undefined)
      assert(0, 'no mob_id');
    if (track_id === undefined)
      assert(0, 'no track_id');
    if (abs_frame === undefined)
      assert(0, 'no abs_frame');
    if (abs_frame_set === undefined)
      assert(0, 'no abs_frame_set');
    return cmd_eval(aaf_in, 'trim', {mob_id, track_id, abs_frame,
      abs_frame_set}, 'Trim');
  }, [aaf_in, cmd_eval]);
  let cmd_mark_in = useCallback((mob_id, abs_frame)=>{
    if (mob_id === undefined)
      assert(0, 'no mob_id');
    if (abs_frame === undefined)
      assert(0, 'no abs_frame');
    return cmd_eval(aaf_in, 'mark_in', {mob_id, abs_frame}, 'Mark In');
  }, [aaf_in, cmd_eval]);
  let cmd_mark_out = useCallback((mob_id, abs_frame)=>{
    if (mob_id === undefined)
      assert(0, 'no mob_id');
    if (abs_frame === undefined)
      assert(0, 'no abs_frame');
    return cmd_eval(aaf_in, 'mark_out', {mob_id, abs_frame}, 'Mark Out');
  }, [aaf_in, cmd_eval]);
  let cmd_mark_clip = useCallback((mob_id, abs_frame)=>{
    if (mob_id === undefined)
      assert(0, 'no mob_id');
    if (abs_frame === undefined)
      assert(0, 'no abs_frame');
    return cmd_eval(aaf_in, 'mark_clip', {mob_id, abs_frame}, 'Mark Clip');
  }, [aaf_in, cmd_eval]);
  let cmd_clear_both_marks = useCallback(mob_id=>eserf(function*
  _cmd_clear_both_marks(){
    if (mob_id === undefined)
      assert(0, 'no mob_id');
    let {aaf_in: _aaf_in} = yield cmd_eval(aaf_in, 'clear_mark_in', {mob_id},
      'Clear Mark In');
    return yield cmd_eval(_aaf_in, 'clear_mark_out', {mob_id},
      'Clear Mark Out');
  }), [aaf_in, cmd_eval]);
  let cmd_cut = useCallback((mob_id, abs_frame)=>{
    if (mob_id === undefined)
      assert(0, 'no mob_id');
    if (abs_frame === undefined)
      assert(0, 'no abs_frame');
    if (!lbin?.rec_monitor_in?.tracks)
      return;
    let data = lbin.rec_monitor_in.tracks
      .filter(track=>{
        let track_cuts = editor.cuts_get([track]);
        return !track_cuts.includes(abs_frame);
      })
      .map(track=>({mob_id, track_id: track.id, abs_frame}));
    if (!data.length)
      return;
    return cmd_eval(aaf_in, 'cut', data, 'Cut');
  }, [cmd_eval, lbin?.rec_monitor_in?.tracks, aaf_in]);
  let cmd_lift = useCallback((mob_id, abs_frame_in, abs_frame_out)=>{
    if (mob_id === undefined)
      assert(0, 'no mob_id');
    if (abs_frame_in === undefined)
      assert(0, 'no abs_frame_in');
    if (abs_frame_out === undefined)
      assert(0, 'no abs_frame_out');
    let data = active_track_ids.map(track_id=>{
      return {mob_id, track_id, abs_frame_in, abs_frame_out};
    });
    return cmd_eval(aaf_in, 'lift', data, 'Lift');
  }, [active_track_ids, cmd_eval, aaf_in]);
  let cmd_extract = useCallback((mob_id, abs_frame_in, abs_frame_out)=>{
    if (mob_id === undefined)
      assert(0, 'no mob_id');
    if (abs_frame_in === undefined)
      assert(0, 'no abs_frame_in');
    if (abs_frame_out === undefined)
      assert(0, 'no abs_frame_out');
    let data = active_track_ids.map(track_id=>{
      return {mob_id, track_id, abs_frame_in, abs_frame_out};
    });
    return cmd_eval(aaf_in, 'extract', data, 'Extract');
  }, [active_track_ids, cmd_eval, aaf_in]);
  let cmd_selector_set = useCallback((mob_id, track_id, selector_idx,
    abs_frame)=>{
    if (mob_id === undefined)
      assert(0, 'no mob_id');
    if (track_id === undefined)
      assert(0, 'no track_id');
    if (selector_idx === undefined)
      assert(0, 'no selector_idx');
    if (abs_frame === undefined)
      assert(0, 'no abs_frame');
    return cmd_eval(aaf_in, 'selector_set', {mob_id, track_id, selector_idx,
      abs_frame}, 'Selector Set');
  }, [aaf_in, cmd_eval]);
  let cmd_solo = useCallback((mob_id, track_ids, is_solo)=>{
    if (mob_id === undefined)
      assert(0, 'no mob_id');
    if (track_ids === undefined)
      assert(0, 'no track_ids');
    if (is_solo === undefined)
      assert(0, 'no is_solo');
    let data = track_ids.map(track_id=>({mob_id, track_id, is_solo}));
    return cmd_eval(aaf_in, 'solo', data);
  }, [aaf_in, cmd_eval]);
  let cmd_mute = useCallback((mob_id, track_ids, is_mute)=>{
    if (mob_id === undefined)
      assert(0, 'no mob_id');
    if (track_ids === undefined)
      assert(0, 'no track_ids');
    if (is_mute === undefined)
      assert(0, 'no is_mute');
    let data = track_ids.map(track_id=>({mob_id, track_id, is_mute}));
    return cmd_eval(aaf_in, 'mute', data);
  }, [aaf_in, cmd_eval]);
  let cmd_marker_add = useCallback((mob_id, track_id, abs_frame, color)=>{
    if (mob_id === undefined)
      assert(0, 'no mob_id');
    if (track_id === undefined)
      assert(0, 'no track_id');
    if (abs_frame === undefined)
      assert(0, 'no abs_frame');
    if (color === undefined)
      assert(0, 'no color');
    return cmd_eval(aaf_in, 'marker_add', {track_id, abs_frame, color,
      comment: ''}, 'Marker Add');
  }, [aaf_in, cmd_eval]);
  let undo = useCallback(()=>{
    let lbin_change = lbin_changes[cur_lbin_change_idx];
    if (!lbin_change)
      return;
    let _lbin = _.cloneDeep(lbin);
    lbin_change.diffs.forEach(diff=>{
      deep_diff.revertChange(_lbin, _lbin, diff);
    });
    lbin_set(_lbin);
    aaf_in_set(lbin_change.aaf_in);
    etag_set(lbin_change.etag);
    cur_lbin_change_idx_set(cur_lbin_change_idx - 1);
  }, [cur_lbin_change_idx, lbin, lbin_changes]);
  let redo = useCallback(()=>{
    let lbin_change = lbin_changes[cur_lbin_change_idx + 1];
    if (!lbin_change)
      return;
    let _lbin = _.cloneDeep(lbin);
    lbin_change.diffs.forEach(diff=>{
      deep_diff.applyChange(_lbin, _lbin, diff);
    });
    lbin_set(_lbin);
    aaf_in_set(lbin_change.aaf_in);
    etag_set(lbin_change.etag);
    cur_lbin_change_idx_set(cur_lbin_change_idx + 1);
  }, [cur_lbin_change_idx, lbin, lbin_changes]);
  let action2func = useMemo(()=>({undo, redo}), [redo, undo]);
  useEffect(()=>{
    let unsubscribe = tinykeys(window, key_binding_map_get(action2func));
    return ()=>unsubscribe();
  }, [action2func]);
  useEffect(()=>{
    set_is_rate(org && user_full && user_full.rate==undefined && org.credit
      && org.credit.v<-50);
  }, [org, user_full]);
  let lbin_change_handle = useCallback(_lbin=>{
    lbin_set(_lbin);
    let _rec_editing_tracks;
    if (_lbin.rec_monitor_in)
    {
      _rec_editing_tracks = _lbin.rec_monitor_in.tracks
        .filter(track=>track.is_edit)
        .map(track=>track.id);
    }
    else
      _rec_editing_tracks = [];
    rec_editing_tracks_set(_rec_editing_tracks);
    lbin_changes_set([]);
    cur_lbin_change_idx_set(-1);
  }, [rec_editing_tracks_set]);
  use_effect_eserf(()=>eserf(function* use_effect_load_from_src(){
    if (!initial_src?.file || !initial_src?.etag || !initial_src?.task_id)
      return;
    if (!token || lbin)
      return;
    is_initial_src_loading_set(true);
    let res = yield back_app.editor_aaf_upload(token, user.email,
      {aaf_in: initial_src.file, etag: initial_src.etag,
        task_id: initial_src.task_id});
    if (res.err)
    {
      metric.error('use_effect_load_from_src', str.j2s(res));
      void message_api.error(`file upload failed ${res.err}`);
      is_initial_src_loading_set(false);
      return;
    }
    lbin_change_handle(res.lbin);
    aaf_in_set(res.file);
    etag_set(res.etag);
    is_initial_src_loading_set(false);
  }), [initial_src?.file, initial_src?.etag, initial_src?.task_id, message_api,
    token, user.email, lbin, lbin_change_handle]);
  let is_loading = useMemo(()=>{
    return is_initial_src_loading || are_render_segs_loading;
  }, [are_render_segs_loading, is_initial_src_loading]);
  let loading_state_change_handle = useCallback(_is_loading=>{
    can_play_set(!_is_loading);
    if (can_play && _is_loading)
    {
      rec_playback_rate_set(0);
      rec_playback_rate_before_stopped_set(rec_playback_rate);
    }
    else if (!can_play && !_is_loading)
      rec_playback_rate_set(rec_playback_rate_before_stopped);
  }, [can_play, rec_playback_rate, rec_playback_rate_before_stopped]);
  // XXX colin: wait till token exists before allowing usage
  // XXX colin: change to use ereq and send to metric on err
  let props = {
    name: 'file',
    listType: 'picture',
    action: xurl.url(prefix+'/private/editor/aaf/upload.json',
      {email: user.email, ver: config_ext.ver}),
    onChange(info){
      if (info.file.status != 'uploading')
        console.log(info.file, info.fileList);
      if (info.file.status == 'done')
      {
        let resp = info.file.response;
        if (resp.err)
          return metric.error('error on done', resp.err);
        message_api.success(`${info.file.name} file uploaded successfully`);
        let file = resp.file;
        lbin_change_handle(resp.lbin);
        etag_set(resp.etag);
        info.file.url = xurl.url(prefix+'/private/aaf/get.aaf',
          {email: user.email, file, token, ver: config_ext.ver});
        let file_prev = info.file.name;
        info.file.name =
          <>
            <span>
              {str.trun(file_prev, 50)} {'->'} {str.trun(file, 20)}
            </span>
            <Button icon={<DownloadOutlined/>}/>
          </>;
        aaf_in_set(file);
      }
      else if (info.file.status == 'error')
      {
        let resp = info.file.response;
        let err_id2str = {
          is_not_aaf: 'Is not an AAF format, look at how you exported it',
          invalid_file_type: 'Invalid file type',
          org_no_credit:
            <>
              <Space direction="vertical">
                <div>
                  Contact your Toolium representative to buy more credits
                </div>
                <div>See your credits in profile</div>
                <Contact is_no_txt={true}/>
              </Space>
            </>,
          org_is_disable:
            <>
              <div>Contact your Toolium representative to activate your
                account
              </div>
              <Contact is_no_txt={true}/>
            </>,
          proc_type_unknown: 'Process type unknown',
          no_clips_found_on_sequence: 'No clips found on sequence',
          no_clips_for_writing_sequence: 'No clips for writing sequence',
          no_sequence_found_in_aaf_file: 'No sequence found in AAF file',
          sequence_has_length_of_0: 'Sequence_has_length of 0',
          group_clip_found_on_sequence: 'Group clip found on sequence',
          group_clip_found_on_sequence_2: 'Group clip found on sequence 2',
          unknown_selector_type_found_on_sequence:
            'Unknown selector type found in sequence',
          clip_framerate_does_not_match_sequence_framerate:
            'Clip framerate does not match sequence framerate',
          subclips_with_motion_effects_are_not_supported:
            'Subclips with motion effects are not supported',
          in_greater_equal_out: 'Some of your timecodes are invalid',
        };
        let err_s = err_id2str[resp.err]||resp.err;
        if (err_s==resp.err)
          metric.error('editor_missing_err_id', err_s);
        else
          metric.error('editor_upload_err_'+resp.err);
        info.file.error.message = err_s;
        modal.error({title: `${info.file.name} file upload failed`,
          content: err_s});
        return void message_api.error(`${info.file.name} file upload failed `
          +`${resp.err}`);
      }
    },
  };
  let _on_change = rate=>{
    // XXX colin: ask for feedback and why?
    back_app.user_set_rate(token, user.email, rate);
    if (rate<4)
      return void set_is_rate(false);
    // XXX colin: change to be toolium.org
    window.location.href = 'https://www.trustpilot.com/review/www.toolium.org';
  };
  if (token)
    props.headers = ereq.auth_hdr(token);
  if (is_rate)
  {
    modal.confirm({
      title: t('Rate the app to help us improve'),
      content: <Clickable>
        <Rate onChange={_on_change} allowHalf defaultValue={3.5} />
      </Clickable>,
      okButtonProps: {disabled: true, style: {display: 'none'}},
      cancelText: t('I don\'t wanna'),
      onOk(){},
      onCancel(){},
    });
  }
  else
    Modal.destroyAll();
  if (!token)
    return <Loading/>;
  return <>
    {message_ctx_holder}
    {modal_ctx_holder}
    {is_loading && <editor.Loading_overlay />}
    {!qs_o.dbg && <Desktop_required_modal
      title={t('Desktop is required for highlighter')} />}
    <Modal
      title={t('This is premium feature, contact us at support@toolium.org')}
      open={is_premium_modal_open}
      onOk={()=>is_premium_modal_open_set(false)}
      onCancel={()=>is_premium_modal_open_set(false)}
    />
    {!initial_src && <Row justify="center">
      <Col>
        <Space direction="vertical" size="large" align="center">
          <Row><Title>HIGHLIGHTER</Title></Row>
          <Row>
            <Upload {...props}>
              <Clickable>
                <Button type="primary"
                  style={{height: '10vw', width: '60vw', fontSize: '3vw'}}
                  icon={<UploadOutlined />}>{t('Click to Upload AAF')}</Button>
              </Clickable>
            </Upload>
          </Row>
        </Space>
      </Col>
    </Row>}
    <Divider/>
    <div ref={editor_ref} style={{width: '100%', minWidth: '1146px',
      minHeight: '90vh', height: '1px', position: 'relative'}}>
      <Row style={{height: '80%'}}>
        <Col span={24} style={{height: '100%'}}>
          <Composer_panel lbin={lbin} fps={fps} token={token} aaf_in={aaf_in}
            rec_playing_toggle={rec_playing_toggle} user={user}
            rec_playback_rate={rec_playback_rate} rec_frame={rec_frame}
            video_render_segs={video_render_segs} etag={etag}
            audio_render_segs={audio_render_segs} undo={undo} redo={redo}
            on_rec_frame_change={rec_frame_handle}
            cmd_cut={cmd_cut} zoom={zoom} lbin_changes={lbin_changes}
            on_zoom_change={zoom_change_handle}
            cmd_mark_in={cmd_mark_in} cmd_mark_out={cmd_mark_out}
            cmd_clear_both_marks={cmd_clear_both_marks}
            cmd_extract={cmd_extract} cmd_lift={cmd_lift}
            cmd_marker_add={cmd_marker_add} go_to_prev_cut={go_to_prev_cut}
            go_to_next_cut={go_to_next_cut} loading_cmd={loading_cmd}
            on_playback_rate_change={rec_playback_rate_handle}
            onClick={()=>focused_panel_set('preview')}
            cur_lbin_change_idx={cur_lbin_change_idx}
            on_loading_state_change={loading_state_change_handle} />
        </Col>
      </Row>
      <Row style={{height: '20%'}}>
        <Col span={24}>
          <Timeline_panel token={token} lbin={lbin} zoom={zoom}
            on_zoom_change={zoom_set} scroll_x={scroll_x}
            on_scroll_x_change={scroll_x_set} focused_panel={focused_panel}
            tracks_wrapper_width={tracks_wrapper_width}
            on_tracks_wrapper_width_change={tracks_wrapper_width_set}
            rec_frame={rec_frame} on_rec_frame_change={rec_frame_handle}
            rec_playback_rate={rec_playback_rate}
            on_rec_playback_rate_change={rec_playback_rate_handle}
            on_lbin_change={lbin_set} premium_modal_open={premium_modal_open}
            onClick={()=>focused_panel_set('timeline')}
            cmd_mark_in={cmd_mark_in} cmd_mark_out={cmd_mark_out}
            cmd_cut={cmd_cut} cmd_mark_clip={cmd_mark_clip}
            cmd_clear_both_marks={cmd_clear_both_marks} cmd_lift={cmd_lift}
            cmd_extract={cmd_extract} cmd_selector_set={cmd_selector_set}
            cmd_solo={is_allow_solo_mute ? cmd_solo : null}
            cmd_mute={is_allow_solo_mute ? cmd_mute : null}
            play_in_to_out={play_in_to_out}
            cmd_toggle_lock={is_allow_edit ? premium_modal_open : null}
            cmd_trim={is_allow_edit ? cmd_trim : null}
            go_to_prev_cut={go_to_prev_cut}
            go_to_next_cut={go_to_next_cut} />
        </Col>
      </Row>
    </div>
  </>;
};

export default auth.with_auth_req(E);
