// LICENSE_CODE MIT
import eserf from '../../../util/eserf.js';
import ereq from '../../../util/ereq.js';
import je from '../../../util/je.js';
import config_ext from './config_ext.js';
import assert from 'assert';

let E = {};
export default E;
let prefix = config_ext.back.app.url;

E.user_get = (token, email)=>eserf(function* back_app_user_get(){
  let res = yield ereq.get(prefix+'/private/user/get.json',
    {qs: {email, ver: config_ext.ver}, no_print: true,
      headers: ereq.auth_hdr(token)});
  if (res.err)
  {
    je.delete('user'); // XXX colin: can set user.err
    return res;
  }
  je.set('user', res.data, {recursive: true});
  return res.data;
});

E.org_get_plan = ()=>eserf(function* back_app_org_get_plan(){
  let res = yield ereq.get(prefix+'/pub/org/get_plan.json',
    {qs: {ver: config_ext.ver}, no_print: true});
  if (res.err)
    return res;
  return res.data;
});

E.org_get_cost = ()=>eserf(function* back_app_org_get_plan(){
  let res = yield ereq.get(prefix+'/pub/org/cost_get.json',
    {qs: {ver: config_ext.ver}, no_print: true});
  if (res.err)
    return res;
  return res.data;
});

E.org_user_add = (token, email, email_invite)=>eserf(function*
back_app_org_user_add(){
  let res = yield ereq.get(prefix+'/private/org/user_add.json',
    {qs: {ver: config_ext.ver, email, email_invite}, no_print: true,
      headers: ereq.auth_hdr(token)});
  if (res.err)
    return res;
  return res.data;
});

E.user_init = (token, email)=>eserf(function* back_app_user_init(){
  let res = yield ereq.get(prefix+`/pub/user/init_${
    config_ext.is_sbase ? 'sbase' : 'auth0'}.json`,
  {qs: {email, ver: config_ext.ver, insert: true},
    headers: ereq.auth_hdr(token)});
  if (res.err)
    return {err: res.err};
  je.set('user', res.data, {recursive: true});
  return res.data;
});

E.user_is_uniq_nick = ({token, email, nick})=>eserf(function*
back_app_user_is_uniq_nick(){
  let res = yield ereq.get(prefix+'/private/user/is_uniq.json',
    {qs: {email, ver: config_ext.ver, key: 'nick', v: nick},
      headers: ereq.auth_hdr(token)});
  if (res.err)
    return {err: res.err};
  return {is_uniq: !res.data.err};
});

E.user_set_rate = (token, email, rate)=>eserf(function*
back_app_user_set_rate(){
  return yield user_set(token, email, 'rate', rate);
});

let user_set = (token, email, key, v)=>eserf(function* back_app_user_set(){
  let res = yield ereq.get(prefix+'/private/user/set.json',
    {qs: {email, ver: config_ext.ver, key, v},
      headers: ereq.auth_hdr(token)});
  // XXX colin: add metric.error on failure
  if (res.err)
    return {err: res.err};
  je.set('user.'+key, v);
  return res.data;
});

E.user_set_is_x_start_pay = (token, email, is_x_start_pay)=>eserf(function*
back_app_user_set_is_x_start_pay(){
  return yield user_set(token, email, 'is_x_start_pay', is_x_start_pay);
});

E.user_set_tbin_cols = (token, email, tbin_cols)=>eserf(function*
back_app_user_set_tbin_cols(){
  return yield user_set(token, email, 'tbin_cols', tbin_cols);
});

E.user_set_org_id_select = (token, email, org_id)=>eserf(function*
back_app_user_set_org_select(){
  return yield user_set(token, email, 'org_id_select', org_id);
});

E.user_set_grouper_setting = (token, email, setting, v)=>eserf(function*
back_app_user_set_grouper_setting(){
  return yield user_set(token, email, 'setting.grouper.'+setting, v);
});

E.user_set_editor_setting = (token, email, setting, v)=>eserf(function*
back_app_user_set_editor_setting(){
  return yield user_set(token, email, 'setting.editor.'+setting, v);
});

E.user_email_resend = (token, email)=>eserf(function*
back_app_user_email_resend(){
  let res = yield ereq.get(prefix+'/pub/user/email_resend.json', {qs: {
    email, ver: config_ext.ver, token}, headers: ereq.auth_hdr(token)});
  if (res.err)
    return {err: res.err};
  return res.data;
});

E.user_onboard = (token, email, first, last, nick, phone,
  company)=>eserf(function* back_app_user_onboard(){
  let ess = [];
  ess.push(user_set(token, email, 'first', first));
  ess.push(user_set(token, email, 'last', last));
  ess.push(user_set(token, email, 'nick', nick));
  ess.push(user_set(token, email, 'phone', phone));
  ess.push(user_set(token, email, 'company', company));
  ess.push(user_set(token, email, 'flag.is_onboard', true));
  let ess_ret = yield this.wait_ret(ess);
  return ess_ret;
});

E.user_early_access_editor = (token, email)=>user_set(token, email,
  'flag.is_early_access_editor', true);

E.org_order_create = (token, email, org_id, order_id, sub_id,
  vendor, src)=>eserf(function* back_app_org_order_create(){
  let res = yield ereq.get(prefix+'/private/org/order/create.json',
    {qs: {email, ver: config_ext.ver, order_id, sub_id, src, vendor, org_id},
      headers: ereq.auth_hdr(token)});
  if (res.err)
    return {err: res.err};
  return res.data;
});

E.org_order_capture = (token, email, org_id, order_id, sub_id,
  vendor, src)=>eserf(function* back_app_org_order_capture(){
  let res = yield ereq.get(prefix+'/private/org/order/capture.json',
    {qs: {email, ver: config_ext.ver, order_id, sub_id, src, vendor, org_id},
      headers: ereq.auth_hdr(token)});
  if (res.err)
    return {err: res.err};
  return res.data;
});

E.org_pay_sub_success = (token, email, org_id, order_id, vendor, src,
  sub_id)=>eserf(function* back_app_org_pay_sub_success(){
  let res = yield ereq.get(prefix+'/private/org/pay/sub_success.json',
    {qs: {email, ver: config_ext.ver, order_id, sub_id, src, vendor, org_id},
      headers: ereq.auth_hdr(token)});
  if (res.err)
    return {err: res.err};
  return res.data;
});

E.org_pay_capture_success = (token, email, org_id, order_id, vendor, src,
  pay_src, payer_id, pay_id)=>eserf(function*
back_app_org_pay_capture_success(){
  let res = yield ereq.get(prefix+'/private/org/pay/capture_success.json',
    {qs: {email, ver: config_ext.ver, order_id, payer_id, pay_id, src, vendor,
      org_id}, headers: ereq.auth_hdr(token)});
  if (res.err)
    return {err: res.err};
  return res.data;
});

E.org_task_list = (token, email, org_id, type)=>eserf(function*
back_app_org_task_list(){
  let res = yield ereq.get(prefix+'/private/org/task/get_list.json',
    {qs: {email, ver: config_ext.ver, org_id, type},
      headers: ereq.auth_hdr(token)});
  if (res.err)
    return {err: res.err};
  return res.data;
});

E.proxy_cors = (token, url)=>eserf(function* back_app_proxy_cors(){
  let res = yield ereq.get(prefix+'/private/proxy_cors.json',
    {qs: {url, ver: config_ext.ver}, headers: ereq.auth_hdr(token)});
  if (res.err)
    return {err: res.err};
  return res.data;
});

E.cutter_aaf_upload = (token, email, tc_json, {task_id,
  aaf_in, is_src_cut_ids, is_rename})=>eserf(function*
back_app_cutter_aaf_upload(){
  let res = yield ereq.get(
    `${config_ext.back.app.url}/private/cutter/aaf/upload.json`,
    {headers: ereq.auth_hdr(token), qs: {email, ver: config_ext.ver,
      tc_json, task_id, aaf_in, is_src_cut_ids, is_rename}});
  if (res.err)
    return {err: res.err};
  return res.data;
});

E.editor_aaf_upload = (token, email, {task_id, aaf_in})=>eserf(function*
back_app_editor_aaf_upload(){
  let res = yield ereq.get(
    `${config_ext.back.app.url}/private/editor/aaf/upload.json`,
    {headers: ereq.auth_hdr(token), qs: {email, ver: config_ext.ver,
      task_id, aaf_in}});
  if (res.err)
    return {err: res.err};
  return res.data;
});

E.editor_aaf_get = (token, file)=>eserf(function*
back_app_editor_aaf_get(){
  let res = yield ereq.get(
    `${config_ext.back.app.url}/private/aaf/get.aaf`,
    {headers: ereq.auth_hdr(token), qs: {file}, is_resp_blob: true});
  if (res.err)
    return {err: res.err};
  return res.data;
});

// eslint-disable-next-line require-yield
E.aff_tc_src_ids_get = (token, aff_in)=>eserf(function*
back_app_aff_tc_src_ids_get(){
  return {20240303: 1, 20240311: 1, 20240317: 1};
});

// XXX colin: add ver
E.cmt = {};
E.cmt.get_list = (token, mob_id)=>eserf(function*
back_app_cmt_get_list(){
  let res = yield ereq.get(
    `${config_ext.back.app.url}/private/cmt/get_list.json`,
    {headers: ereq.auth_hdr(token), qs: {ver: config_ext.ver, mob_id}});
  if (res.err)
    return {err: res.err};
  return res.data;
});
E.cmt.insert = (token, msg, svg, tc, fps, mob_id)=>eserf(function*
back_app_cmt_insert(){
  let res = yield ereq.post(
    `${config_ext.back.app.url}/private/cmt/insert.json`,
    {qs: {ver: config_ext.ver, msg, tc, fps, mob_id}, data: {svg},
      headers: ereq.auth_hdr(token)});
  if (res.err)
    return {err: res.err};
  return res.data;
});
E.cmt.delete = (token, cmt_id)=>eserf(function*
back_app_cmt_delete(){
  let res = yield ereq.post(
    `${config_ext.back.app.url}/private/cmt/delete.json`,
    {qs: {ver: config_ext.ver, cmt_id}, headers: ereq.auth_hdr(token)});
  if (res.err)
    return {err: res.err};
  return res.data;
});

E.ping = ()=>eserf(function* back_app_ping(){
  let res = yield ereq.get(prefix+'/pub/ping.json',
    {qs: {ver: config_ext.ver}, no_print: true});
  if (res.err)
    return res;
  return res.data;
});

E.editor = {cmd_q: []};
let is_running;
E.editor.cmd = (token, aaf_in, cmd, data)=>eserf(function*
back_app_editor_cmd(){
  let res = yield ereq.get(
    `${config_ext.back.app.url}/private/editor/cmd.json`,
    {qs: {ver: config_ext.ver, aaf_in, cmd, ...data},
      headers: ereq.auth_hdr(token)});
  if (res.err)
    return {err: res.err};
  return res.data;
});
E.editor.cmds = (token, aaf_in, cmd, data)=>eserf(function*
back_app_editor_cmds(){
  let res;
  this.finally(()=>{
    is_running = false;
    let es = E.editor.cmd_q.shift();
    if (es)
      es.continue();
  });
  if (is_running)
  {
    let es = this.wait();
    E.editor.cmd_q.push(es);
    yield es;
  }
  is_running = true;
  for (let i = 0; i < data.length; i++)
  {
    let _aaf_in = res ? res.file : aaf_in;
    res = yield E.editor.cmd(token, _aaf_in, cmd, data[i]);
    if (res.err)
      return {err: res.err};
  }
  return res;
});

E.log = {};
E.log.setting_set = (token, key, v)=>eserf(function* back_app_log_setting_get(){
  let res = yield ereq.get(
    `${config_ext.back.app.url}/private/log/set.json`,
    {headers: ereq.auth_hdr(token), qs: {ver: config_ext.ver, key, v}});
  if (res.err)
    return {err: res.err};
  return res.data;
});
E.log.get = (token, id)=>eserf(function* back_app_log_get(){
  let res = yield ereq.get(
    `${config_ext.back.app.url}/private/log/get.json`,
    {headers: ereq.auth_hdr(token), qs: {ver: config_ext.ver, id}});
  if (res.err)
    return {err: res.err};
  return res.data;
});
E.log.get_list = token=>eserf(function* back_app_log_get_list(){
  let res = yield ereq.get(
    `${config_ext.back.app.url}/private/log/get_list.json`,
    {headers: ereq.auth_hdr(token), qs: {ver: config_ext.ver}});
  if (res.err)
    return {err: res.err};
  return res.data;
});
E.log.insert = (token, lbl, member_ids, fps, cameras,
  hide_scene_take)=>eserf(function* back_app_log_insert(){
  let res = yield ereq.post(
    `${config_ext.back.app.url}/private/log/insert.json`,
    {qs: {ver: config_ext.ver, lbl, member_ids, fps, cameras, hide_scene_take},
      headers: ereq.auth_hdr(token)});
  if (res.err)
    return {err: res.err};
  return res.data;
});
E.log.set = (token, log_id, lbl, cameras, hide_scene_take)=>eserf(function*
back_app_log_set(){
  let res = yield ereq.post(
    `${config_ext.back.app.url}/private/log/set.json`,
    {qs: {ver: config_ext.ver, log_id, lbl, cameras, hide_scene_take},
      headers: ereq.auth_hdr(token)});
  if (res.err)
    return {err: res.err};
  return res.data;
});
E.log.delete = (token, log_id)=>eserf(function*
back_app_log_delete(){
  let res = yield ereq.post(
    `${config_ext.back.app.url}/private/log/delete.json`,
    {qs: {ver: config_ext.ver, log_id}, headers: ereq.auth_hdr(token)});
  if (res.err)
    return {err: res.err};
  return res.data;
});
E.log.export = (token, log_id, fmt)=>eserf(function*
back_app_log_export(){
  let res = yield ereq.post(
    `${config_ext.back.app.url}/private/log/export.json`,
    {qs: {ver: config_ext.ver, log_id, fmt}, headers: ereq.auth_hdr(token)});
  if (res.err)
    return {err: res.err};
  return res.data;
});

E.pin = {};
E.pin.get = (token, id)=>eserf(function* bac_app_pin_get_list(){
  let res = yield ereq.get(
    `${config_ext.back.app.url}/private/pin/get.json`,
    {headers: ereq.auth_hdr(token), qs: {ver: config_ext.ver, id}});
  if (res.err)
    return {err: res.err};
  return res.data;
});
E.pin.get_list = (token, log_id)=>eserf(function* back_app_pin_get_list(){
  let res = yield ereq.get(
    `${config_ext.back.app.url}/private/pin/get_list.json`,
    {headers: ereq.auth_hdr(token), qs: {ver: config_ext.ver, log_id}});
  if (res.err)
    return {err: res.err};
  return res.data;
});
E.pin.insert = (token, log_id, tc_in, tc_out, cameras, scene, shot, take,
  msg)=>eserf(function* back_app_pin_insert(){
  let res = yield ereq.post(
    `${config_ext.back.app.url}/private/pin/insert.json`,
    {qs: {ver: config_ext.ver, log_id, tc_in, tc_out, cameras, scene, shot,
      take, msg}, headers: ereq.auth_hdr(token)});
  if (res.err)
    return {err: res.err};
  return res.data;
});
E.pin.set = (token, pin_id, tc_in, tc_out, cameras, scene, shot, take,
  msg)=>eserf(function* back_app_pin_set(){
  let res = yield ereq.post(
    `${config_ext.back.app.url}/private/pin/set.json`,
    {qs: {ver: config_ext.ver, pin_id, tc_in, tc_out, cameras, scene, shot,
      take, msg}, headers: ereq.auth_hdr(token)});
  if (res.err)
    return {err: res.err};
  return res.data;
});
E.pin.delete = (token, pin_id)=>eserf(function* back_app_pin_delete(){
  let res = yield ereq.post(
    `${config_ext.back.app.url}/private/pin/delete.json`,
    {qs: {ver: config_ext.ver, pin_id}, headers: ereq.auth_hdr(token)});
  if (res.err)
    return {err: res.err};
  return res.data;
});

E.proj = {};
E.proj.get = (token, id)=>eserf(function* back_app_proj_get(){
  let res = yield ereq.get(
    `${config_ext.back.app.url}/private/proj/get.json`,
    {headers: ereq.auth_hdr(token), qs: {ver: config_ext.ver, id}});
  if (res.err)
    return {err: res.err};
  return res.data;
});
E.proj.get_list = token=>eserf(function* back_app_proj_get_list(){
  let res = yield ereq.get(
    `${config_ext.back.app.url}/private/proj/get_list.json`,
    {headers: ereq.auth_hdr(token), qs: {ver: config_ext.ver}});
  if (res.err)
    return {err: res.err};
  return res.data;
});
E.proj.insert = (token, lbl)=>eserf(function* back_app_proj_insert(){
  let res = yield ereq.post(
    `${config_ext.back.app.url}/private/proj/insert.json`,
    {qs: {ver: config_ext.ver, lbl}, headers: ereq.auth_hdr(token)});
  if (res.err)
    return {err: res.err};
  return res.data;
});
E.proj.lbl_set = (token, id, lbl)=>eserf(function* back_app_proj_lbl_set(){
  let res = yield ereq.post(
    `${config_ext.back.app.url}/private/proj/lbl_set.json`,
    {qs: {ver: config_ext.ver, id, lbl},
      headers: ereq.auth_hdr(token)});
  if (res.err)
    return {err: res.err};
  return res.data;
});
E.proj.delete = (token, id)=>eserf(function* back_app_proj_delete(){
  let res = yield ereq.post(
    `${config_ext.back.app.url}/private/proj/delete.json`,
    {qs: {ver: config_ext.ver, id}, headers: ereq.auth_hdr(token)});
  if (res.err)
    return {err: res.err};
  return res.data;
});

E.file = {};
E.file.get = (token, file_id)=>eserf(function*
back_app_file_get(){
  let res = yield ereq.get(`${config_ext.back.app.url}/private/file/get.json`,
    {headers: ereq.auth_hdr(token), qs: {ver: config_ext.ver, file_id}});
  if (res.err)
    return {err: res.err};
  return res.data;
});
E.file.ls = (token, proj_id)=>eserf(function* back_app_file_ls(){
  let res = yield ereq.get(
    `${config_ext.back.app.url}/private/file/ls.json`,
    {headers: ereq.auth_hdr(token), qs: {ver: config_ext.ver, proj_id}});
  if (res.err)
    return {err: res.err};
  return res.data;
});
E.file.touch = (token, file_id, is_dir)=>eserf(function* back_app_file_touch(){
  let res = yield ereq.get(
    `${config_ext.back.app.url}/private/file/touch.json`,
    {headers: ereq.auth_hdr(token), qs: {ver: config_ext.ver, file_id,
      is_dir}});
  if (res.err)
    return {err: res.err};
  return res.data;
});
E.file.rm = (token, file_id)=>eserf(function* back_app_file_rm(){
  let res = yield ereq.get(
    `${config_ext.back.app.url}/private/file/rm.json`,
    {headers: ereq.auth_hdr(token), qs: {ver: config_ext.ver, file_id}});
  if (res.err)
    return {err: res.err};
  return res.data;
});
E.file.mv = (token, src_id, target_id)=>eserf(function* back_app_file_mv(){
  let res = yield ereq.get(
    `${config_ext.back.app.url}/private/file/mv.json`,
    {headers: ereq.auth_hdr(token), qs: {ver: config_ext.ver, src_id,
      target_id}});
  if (res.err)
    return {err: res.err};
  return res.data;
});
E.file.cp = (token, src_id, target_id)=>eserf(function* back_app_file_cp(){
  let res = yield ereq.get(
    `${config_ext.back.app.url}/private/file/cp.json`,
    {headers: ereq.auth_hdr(token), qs: {ver: config_ext.ver, src_id,
      target_id}});
  if (res.err)
    return {err: res.err};
  return res.data;
});
E.file.upload = (token, file_ids, files, progress_tx_cb)=>eserf(function*
back_app_file_upload(){
  if (file_ids.length != files.length)
    assert(0, 'file_ids and files have different length');
  let data = {};
  for (let file of files)
    data[file.name] = file;
  let res = yield ereq.post_upload(
    `${config_ext.back.app.url}/private/file/upload.json`,
    {headers: ereq.auth_hdr(token), qs: {ver: config_ext.ver, file_ids},
      data, progress_tx_cb});
  if (res.err)
    return {err: res.err};
  return res.data;
});
E.file.tcode_wait = (token, file_id)=>eserf(function*
back_app_file_tcode_wait(){
  let res = yield ereq.get(
    `${config_ext.back.app.url}/private/file/tcode_wait.json`,
    {headers: ereq.auth_hdr(token), qs: {ver: config_ext.ver, file_id}});
  if (res.err)
    return {err: res.err};
  return res.data;
});

E.file_cmt = {};
E.file_cmt.insert = (token, file_id, msg, is_tc, frame)=>eserf(function*
back_app_file_cmt_insert(){
  let res = yield ereq.post(
    `${config_ext.back.app.url}/private/file_cmt/insert.json`,
    {qs: {ver: config_ext.ver, file_id, msg, is_tc, frame},
      headers: ereq.auth_hdr(token)});
  if (res.err)
    return {err: res.err};
  return res.data;
});
E.file_cmt.delete = (token, file_id, cmt_id)=>eserf(function*
back_app_file_cmt_delete(){
  let res = yield ereq.post(
    `${config_ext.back.app.url}/private/file_cmt/delete.json`,
    {qs: {ver: config_ext.ver, file_id, cmt_id},
      headers: ereq.auth_hdr(token)});
  if (res.err)
    return {err: res.err};
  return res.data;
});

E.proj_link = {};
E.proj_link.get = (proj_id, proj_link_id, passwd)=>eserf(function*
back_app_proj_link_get(){
  let res = yield ereq.get(
    `${config_ext.back.app.url}/pub/proj_link/get.json`,
    {qs: {ver: config_ext.ver, proj_id, proj_link_id, passwd}});
  if (res.err)
    return {err: res.err};
  return res.data;
});
E.proj_link.insert = (token, proj_id, lbl, is_private, passwd,
  file_inodes)=>eserf(function* back_app_proj_link_insert(){
  let res = yield ereq.post(
    `${config_ext.back.app.url}/private/proj_link/insert.json`,
    {qs: {ver: config_ext.ver, proj_id, lbl, is_private, passwd, file_inodes},
      headers: ereq.auth_hdr(token)});
  if (res.err)
    return {err: res.err};
  return res.data;
});
E.proj_link.set = (token, proj_id, proj_link_id, key, v)=>eserf(function*
back_app_proj_link_set(){
  let res = yield ereq.post(
    `${config_ext.back.app.url}/private/proj_link/set.json`,
    {qs: {ver: config_ext.ver, proj_id, proj_link_id, key, v},
      headers: ereq.auth_hdr(token)});
  if (res.err)
    return {err: res.err};
  return res.data;
});
E.proj_link.ls = (proj_id, proj_link_id, passwd)=>eserf(function*
back_app_proj_link_ls(){
  let res = yield ereq.post(
    `${config_ext.back.app.url}/pub/proj_link/ls.json`,
    {qs: {ver: config_ext.ver, proj_id, proj_link_id, passwd}});
  if (res.err)
    return {err: res.err};
  return res.data;
});
E.proj_link.delete = (token, proj_id, proj_link_id)=>eserf(function*
back_app_proj_link_delete(){
  let res = yield ereq.post(
    `${config_ext.back.app.url}/private/proj_link/delete.json`,
    {qs: {ver: config_ext.ver, proj_id, proj_link_id},
      headers: ereq.auth_hdr(token)});
  if (res.err)
    return {err: res.err};
  return res.data;
});
E.proj_link.file_get = (inode, proj_link_id, passwd)=>eserf(function*
back_app_file_get_by_inode(){
  let res = yield ereq.get(
    `${config_ext.back.app.url}/pub/proj_link/file_get.json`,
    {qs: {ver: config_ext.ver, inode, proj_link_id, passwd}});
  if (res.err)
    return {err: res.err};
  return res.data;
});
